.loggedIn-session-container {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
}

.locations-container {
    display: flex;
    width: 100%;
}
.device-type {
    display: flex;
    padding: 0rem 1.125rem;
    align-items: flex-start;
}

.loggedIn-text-container {
    display: flex;
    flex-direction: column;
}

.loggedIn-text-badge {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
}

.loggedIn-text-model {
    display: flex;
    align-items: center;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #344054;
}

.loggedIn-current-badge {
    display: flex;
    padding: 0.125rem 0.5rem;
    background: #D0E0E8;
    border-radius: 1rem;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 1.125rem;
    align-items: center;
    color: #26404E;
}
.loggedIn-dot {
    height: 0.375rem;
    width: 0.375rem;
    background-color: #039855;
    border-radius: 50%;
    display: flex;
}

.loggedIn-active-session {
    display: flex;
    gap: 0.3125rem;
    padding: 0.125rem 0.5rem 0.125rem 0.375rem;
    border: 0.0938rem solid #039855;
    border-radius: 1rem;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 1.125rem;
    align-items: center;
    color: #027A48;
}

.loggedIn-supporting-text {
    display: flex;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #475467;
}