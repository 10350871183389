.modal-header-generate-report {
    display: flex;
    flex-direction: row;
    /* align-items: center; */
    padding: 0px;
    isolation: isolate;
    width: 100%;
    height: 136px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.modal-header-content-generate-report {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px 24px 0px;
    gap: 16px;
    width: 100%;
    box-sizing: border-box;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    z-index: 0;
}

.modal-content-generate-report {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px 24px;
    gap: 0.5rem;
    width: 100%;
    box-sizing: border-box;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}