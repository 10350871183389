.error-page-container {
  display: grid; 
  grid-auto-columns: 1fr; 
  grid-template-columns: 1fr 4.5fr; 
  gap: 0em 0px; 
}
.error-page-left-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 8rem 6rem 2rem 7rem;
    gap: 3rem;
    flex: none;
    order: 0;
    flex-grow: 1;
}
.error-page-right-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: none;
    order: 1;
    flex-grow: 1;
}
.error-page-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0rem;
    gap: 2rem;
    width: 35rem;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}
.error-page-heading {
    width: 35rem;
    height: 1.5rem;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #26404E;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}
.error-page-buttons {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0rem;
    gap: 1rem;
    width: 26.875rem;
    height: 3rem;
    flex: none;
    order: 1;
    flex-grow: 0;
}
.error-page-resources {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0rem;
    gap: 3.5rem;
    width: 35rem;
    height: 12.75rem;
    flex: none;
    order: 2;
    align-self: stretch;
    flex-grow: 0;
}
.error-page-image-container {
    display: flex;
    flex-wrap: nowrap;
    flex: none;
    order: 0;
    flex-grow: 0;
    justify-content: center;
    align-content: center;
}
.error-page-image {
    justify-self: center;
    align-self: center;
}