.popup-container {
    box-shadow: 1rem 0.75rem 1rem -0.25rem rgba(16, 24, 40, 0.08), 0rem 0.25rem 0.375rem -0.125rem rgba(16, 24, 40, 0.03);
    border-radius: 0.5rem;
}

.marker-container {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.radar-box {
    width: 41px;
    height: 41px;
    position: relative;
    position: absolute;
    cursor: pointer;
}

.pointers-static {
    display: flex;
    justify-content: center;
    position: absolute;
    z-index: 1024;
    left: 11.582011%;
    right: 10.5820106%;
    top: -10%;
    bottom: 50%;
    will-change: transform;
    transform-origin: 50% 100%;
    border-radius: 50% 50% 0 0 / 100% 100% 0 0;
    background-image: linear-gradient(0deg, 
      rgba(255, 255, 255, 0) 20%, 
      rgba(255, 255, 255, 0) 70%,
      rgba(255, 255, 255, 0) 100%
      );
    clip-path: polygon(100% 0, 
      100% 10%, 
      50% 100%, 0 100%, 0 0);
}

.cluster-marker {
  color: #FFF;
  background-color: #365A6D;
  border-radius: 70%;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes rotate360 {
    0% {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(-360deg);
    }
}