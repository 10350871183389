.notification-config-details-container {
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    gap: 2rem;
    padding: 2rem 2rem 3rem 2rem;
}

.notification-details-back-button {
    display: flex;
}

.notification-config-details-header {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 1.25rem;
}

.notification-config-details-container-button {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    gap: 1.25rem;
}

.notitfication-config-details-button {
    display: flex;
    gap: 0.75rem;
}

.notification-config-body-container {
    display: flex;
    box-sizing: border-box;
    width: 100%;
    gap: 2rem;
}

.notification-config-body-text {
    color: #344054;
    font-family: Inter;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 500;
    width: 17.5rem;
}