.notification-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0.25rem 0.25rem;
    width: 1.875rem;
    /* height: 1.375rem; */
    background: #ECFDF3;
    mix-blend-mode: multiply;
    border-radius: 1rem;
    flex: none;
    order: 1;
    flex-grow: 0;
}
.notification-text {
    /* width: 0.875rem; */
    /* height: 1.125rem; */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 1.125rem;
    text-align: center;
    color: #027A48;
    flex: none;
    order: 0;
    display: flex;
    flex-grow: 0;
}