.sidebar-container-main {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    /* padding: 2rem 1rem 1.25rem 1rem; */
    width: 17.5rem;
    background: #FFFFFF;
    border-right: 0.063rem solid #EAECF0;
    flex: none;
    order: 0;
    align-self: stretch;
    height: 100%;
    overflow-x: hidden;
    flex-grow: 0;
}
.sidebar-contents {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0rem;
    gap: 4rem;
    flex: none;
    order: 0;
    align-self: stretch;
}