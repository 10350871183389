.configuration-container {
    display: grid; 
    grid-auto-columns: 1fr; 
    grid-template-columns: 1fr; 
    grid-template-rows: minmax(12.125rem, 12.125rem) minmax(36.8123rem, 36.8123rem);
    padding: 0px 2rem 0rem;
    gap: 1.5rem; 
    height: 100%;
}

.configuration-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1.5rem 0rem 0rem;
    gap: 1.25rem;
}

.configuration-tabs {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0rem;
    gap: 0.5rem;
    border-bottom: 0.0625rem solid #EAECF0;
    width: 100%;
}

.configuration-body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0rem;
    gap: 1.25rem;
}

.configuration-body-row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0rem;
    gap: 2rem;
}

.device-button {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.device-input-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.625rem 0.875rem;
    gap: 0.5rem;
    background: #F9FAFB;
    border-width: 0.0625rem 0rem 0.0625rem 0.0625rem;
    border-style: solid;
    border-color: #D0D5DD;
    border-radius: 0.5rem 0rem 0rem 0.5rem;
    width: 25.4375rem;
}

.device-input-text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #667085;
}

.device-copy-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.625rem 0.875rem;
    gap: 0.5rem;
    width: 6.5625rem;
    background: #FFFFFF;
    border-width: 0.0625rem;
    border-style: solid;
    border-color: #D0D5DD;
    border-radius: 0rem 0.5rem 0.5rem 0rem;
    cursor: pointer;
}

.device-copy-button:active {
    background: #F9FAFB;
}

.device-copy-text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #344054;
}

.configuration-text-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0rem;
    height: 4.375rem;
}

.asset-name-input {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.625rem 0.875rem;
    gap: 0.5rem;
    height: 2.75rem;
    width: 32rem;
    background: #FFFFFF;
    border: 0.0625rem solid #D0D5DD;
    box-shadow: 0rem 0.0625rem 0.125rem rgba(16, 24, 40, 0.05);
    border-radius: 0.5rem;
    font-size: 1rem;
    font-family: Inter;
    line-height: 1.5rem;
    color: #101828;
}

.configuration-main-text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #344054;
    width: 17.5rem;
}

.configuration-supporting-text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #475467;
    width: 17.5rem;
}

.configuration-error-message {
    width: 22.5rem;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    color: #F04438;
    flex: none;
    flex-grow: 0;
    cursor: default;
}