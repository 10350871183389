.selection-wrapper-countriesDropdown {
    display: flex;
    justify-content: center;
    /* padding-top: 1.25rem; */
}

.selections-container-countriesDropdown {
    /* width: 22.5rem; */
}

.selection-button-countriesDropdown {
    border: 1px solid #D0D5DD;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 0.5rem;
    /* height: 44px; */
    background: #FFFFFF;
    /* width: 100%; */

    height: 22px;
    /* temporary */
    width: 332px;
    /* temporary */
    line-height: 22px;

    padding: 0.625rem 0.75rem;
    margin-bottom: 3.4px;
    position: relative;
    text-align: left;
    display: flex;
    gap: 0.5rem;
    align-items: center;

    resize: none;
}

.selection-button-countriesDropdown::placeholder {
    font-family: Inter;
    color: #D0D5DD;
    line-height: 22px;
}

.selection-button-countriesDropdown:hover {
    /* cursor: pointer; */
}

.selection-button-countriesDropdown::after {
    position: absolute;
    right: 1.188rem;
    /* top: 1.219rem; */
    content: url(../../image/caretDown.svg);
}

.selection-button-countriesDropdown.expanded {
    border: 1px solid #73A2BA;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #F4EBFF;
}

.selection-button-countriesDropdown.expanded::after {
    content: url(../../image/caretDown.svg);
}

.selection-button-countriesDropdown:focus {
    outline: none;
}

ul.options-countriesDropdown {
    border: 1px solid #EAECF0;
    /* Shadow/lg */

    box-shadow: 0rem 0.75rem 1rem -0.25rem rgba(16, 24, 40, 0.08), 0rem 0.25rem 0.375rem -0.125rem rgba(16, 24, 40, 0.03);
    border-radius: 0.5rem;
    display: none;
    max-height: 22rem;
    list-style: none;
    padding: 0.25rem 0.25rem;
    margin-top: 1px;
    position: absolute;
    background: #FFFFFF;
    width: 31.5rem;
    z-index: 2;
    /* bottom: 2rem; */
    overflow: auto;
}

ul.options-countriesDropdown::-webkit-scrollbar {
    width: 1rem;
}

ul.options-countriesDropdown::-webkit-scrollbar-thumb {
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: #EAECF0;
}

ul.options-countriesDropdown.show {
    display: block;
}

ul.options-countriesDropdown li {
    padding: 0.375rem 0.625rem;
    margin-bottom: 0.25rem;
}

ul.options-countriesDropdown li:active,
ul.options-countriesDropdown li:focus,
ul.options-countriesDropdown li:hover,
ul.options-countriesDropdown li[aria-selected="true"] {
    background: #F9FAFB;
    cursor: pointer;
}

.list-agency-countriesDropdown {
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5rem;
    /* white-space: nowrap; */
    /* overflow: hidden; */
    /* text-overflow: ellipsis; */
    /* max-width: 11.25rem; */
    color: #101828;
    background-color: 'blue';
}

.list-agency-asset-countriesDropdown {
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    /* max-width: 15rem; */
}

.list-asset-id-countriesDropdown {
    font-weight: 400;
    font-size: 1rem;
    /* line-height: 1.25rem; */
    color: #475467;
    position: relative;
    top: 0.188rem;
    white-space: nowrap;
    /* overflow: hidden;
text-overflow: ellipsis; */
    /* padding: 0px 8px 0px 0px; */
    max-width: 90%;
}

.list-agency-id-countriesDropdown {
    font-weight: 400;
    font-size: 1rem;
    /* line-height: 1.25rem; */
    color: #475467;
    position: relative;
    top: 0.188rem;
    /* padding: 0px 8px 0px 0px; */
}

.list-agency-id-countriesDropdown-cc {
    font-weight: 400;
    font-size: 1rem;
    /* line-height: 1.25rem; */
    color: #475467;
    position: relative;
    /* top: 0.188rem; */
    /* padding: 0px 8px 0px 0px; */
}

.btn-list-placeholder-countriesDropdown {
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.25rem;
    color: #667085;
    width: 100%
}

.btn-list-selected-textfield-countriesDropdown {
    /* display: flex;
gap: 8px; */
    /* display: inline-grid;
grid-auto-flow: column;
max-width: 100%;
grid-auto-columns: 1fr;
gap: '8px'; */
}

.btn-list-placeholder-countriesDropdown:focus {
    outline: none;
}

.btn-list-selected-textfield-countriesDropdown:focus {
    outline: none;
}

.btn-list-agency-name-countriesDropdown {
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 13.25rem;
}

.btn-list-agency-nam-countriesDropdowne:focus {
    outline: none;
}

.btn-list-asset-id-countriesDropdown {
    font-weight: 400;
    line-height: 1.5rem;
    color: #475467;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 90%;
}

.btn-list-agency-id-countriesDropdown {
    font-weight: 400;
    line-height: 1.5rem;
    color: #475467;
}

.btn-list-container-countriesDropdown:focus {
    outline: none;
}

.btn-list-container-countriesDropdown {
    display: flex;
    align-items: center;
    gap: 8px;
}

.calling-code-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    align-self: stretch;
    flex-grow: 1;
    border: 1px solid #D0D5DD;
    border-radius: 8px;
    background: #FFFFFF;
    height: 2.75rem;
}

.callingcode-input-box {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 8px;
    gap: 8px;
    height: 2.75rem;
    border: 1px solid #FFFFFF;
    border-radius: 8px;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 1;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}


.selection-button-countriesDropdown-cc {
    border: 1px solid #FFFFFF;
    border-radius: 0.5rem;
    background: #FFFFFF;

    height: 22px;
    /* temporary */
    width: 2.7rem;
    /* temporary */
    line-height: 22px;

    padding: 0.625rem 0.75rem;
    margin-bottom: 3.4px;
    position: relative;
    text-align: left;
    display: flex;
    gap: 0.5rem;
    align-items: center;

    resize: none;
    cursor: pointer;
}

.selection-button-countriesDropdown-cc::placeholder {
    font-family: Inter;
    color: #D0D5DD;
    line-height: 22px;
}

.selection-button-countriesDropdown-cc:hover {
    /* cursor: pointer; */
}

.selection-button-countriesDropdown-cc::after {
    position: absolute;
    right: 0.8rem;
    /* top: 1.219rem; */
    content: url(../../image/caretDown.svg);
}

.selection-button-countriesDropdown-cc.expanded {
    border: 1px solid #73A2BA;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #F4EBFF;
}

.selection-button-countriesDropdown-cc.expanded::after {
    content: url(../../image/caretDown.svg);
}

.selection-button-countriesDropdown-cc:focus {
    outline: none;
}

.inner {
    position: relative;
    height: auto;
}
