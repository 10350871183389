.verify-email-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 6rem;
    gap: 2rem;
    width: 22.5rem;
    margin: auto;
}

.verify-email-header {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    align-items: center;
    width: 22.5rem;
}

.otp-container {
    display: flex;
    flex-direction: column;
}

.resend-email-container {
    display: flex;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #475467;
}

.resend-button {
    padding-left: 0.25rem;
    color: #26404E;
    text-decoration: none;
    font-weight: 600;
}

.email-subtitle {
    padding-bottom: 2rem;
    /* gap: 1rem; */
    /* flex-direction: 'column'; */
}

.invalid-otp-container {
    display: flex;
    width: '100%';
    justify-content: center;
    padding-top: 1rem;
}

.invalid-otp {
    display: flex;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #F04438;
}