.filter-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.625rem 1rem;
    gap: 0.5rem;
    width: 6.438rem;
    /* height: 2.5rem; */
    background: #FFFFFF;
    border: 0.063rem solid #D0D5DD;

    position: relative; /* temporary */
    box-shadow: 0rem 0.063rem 0.125rem rgba(16, 24, 40, 0.05);
    border-radius: 0.5rem;
}

.filter-text {
    font-style: normal;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #344054;
}