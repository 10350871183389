.settings-container {
    padding: 2rem 2rem 2rem;
    display: grid;
    grid-template-columns: 1fr;
    background-color: #F9FAFB;
    /* width: '30%', */
}
.settings-header {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    /* width: 100%; */
}
.settings-title {
    display: flex;
    flex-direction: row;
}
.settings-title-grow {
    flex-grow: 1;
}
.settings-config-tabs {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
    width: 633px;
    /* height: 32px; */
    flex: none;
    order: 0;
    flex-grow: 0;
}
.settings-form-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-top: 0.063rem solid #EAECF0;
}
.settings-section-header {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding-bottom: 1.25rem;
    padding-top: 2rem;
    gap: 20px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 1;
    border-bottom: 0.063rem solid #EAECF0;
}
.settings-actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 12px;
    /* width: 157px; */
    height: 40px;
    flex: none;
    order: 1;
    flex-grow: 0;
}
.settings-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* width: 1160px; */
    /* height: 759px; */
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}
.settings-content-form {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding-bottom: 1.25rem;
    padding-top: 1.25rem;
    /* padding-right: 10rem; */
    gap: 2rem;
    /* height: 73px; */
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 1;
    border-bottom: 0.063rem solid #EAECF0;
    width: '100%',
}
:root {
    --label-width: 0px;
    --input-width: 0px;
}
.settings-input-label {
    width: var(--label-width);
    height: 1.25rem;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #344054;
    /* flex: 3; */
}
.settings-input-label-countries {
    width: var(--label-width);
    height: 2.75rem;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #344054;
    /* flex: 3; */
}
.settings-input-subtitle {
    width: 280px;
    height: 20px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #475467;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}
.settings-name-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 1.5rem;
    width: var(--input-width);
}
.settings-name-field {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 6px;
    /* width: 244px; */
    height: 70px;
    flex: 1;
    order: 0;
    /* flex-grow: 1; */
}
.settings-name-label {
    /* width: 71px; */
    height: 20px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #344054;
    flex: none;
    order: 0;
    flex-grow: 0;
}
.settings-email-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 0.375rem;
    width: var(--input-width);
}
.settings-input-box {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 14px;
    gap: 8px;
    height: 2.75rem;
    border: 1px solid #D0D5DD;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 1;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* width: var(--label-width); */
}
.settingsInput {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 14px;
    gap: 8px;
    width: 512px;
    height: 44px;
    background: #FFFFFF;
    border: 1px solid #D0D5DD;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}
.settingsInput::placeholder {
    font-size: 1rem;
    padding-left: 1.563rem;
}
.settings-label-subtitle {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    
    width: 280px;
    height: 40px;
    
    
    /* Inside auto layout */
    
    flex: none;
    order: 0;
    flex-grow: 0;
}
.settings-upload-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 1.25rem;
    width: 512px;
    /* height: 126px; */
    flex: none;
    order: 1;
    flex-grow: 0;
}
.settings-footer {
    display: flex;
    align-self: stretch;
    justify-content: flex-end;
    padding-top: 1.25rem;
}
.country-selector-container {
    width: 32rem;
    height: 2.75rem;
    border-radius: 0.5rem;
    border-color: #000000;
    background-color: #FFFFFF;
    border: 1px solid #D0D5DD;
}
.country-css {
    /* position: absolute; */
    /* left: 41.3%; */
    /* top: 2%; */
    /* bottom: 10%; */
    /* z-index: 2; */
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    /* background-color: #D0D5DD; */
    width: 32rem;
}
.circle-flag-icon {
    width: 0.25rem;
    height: 0.25rem;
}
.country-selector {
    display: flex;
    flex-direction: row;
}
.email-comments-container {
    display: flex;
    gap: 0.8rem;
    flex-direction: column;
}