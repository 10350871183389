.manage-notification-main-content {
    display: flex;
    flex-direction: column;
    padding: 2rem 2rem 3rem;
    position: relative;
    height: calc(100% - 5rem);
    overflow: auto;
    gap: 2rem;
    background: #FCFCFD;
}

.manage-notification-back-button {
    display: flex;
}

.manage-notification-form {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 1.5rem;
}

.manage-notification-header {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
}

.manage-notification-title {
    display: flex;
    flex-direction: row;
}

.manage-notification-button-group {
    display: flex;
    flex-direction: row;
    gap: 0.75rem;
}

.manage-notification-row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 32px;
}

.manage-notification-row-name {
    display: flex;
    width: 280px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #344054;
}

.manage-notification-phoneNo-css {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 32rem;
    gap: 4px;
}

.manage-notification-input-supporting-text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #475467;
}

.vertical-linear-stepper {
    display: flex;
}

.vertical-linear-stepper-disabled {
    display: flex;
    opacity: 0.6;
}