.parent-avatar-group {
    display: flex;
}

.single-avatar {
    height: 1.5rem;
    width: 1.5rem;
    overflow: hidden;
    border-radius: 50%;
    margin-left: -8px;
    position: relative;
    border: 2px solid #FFFFFF;
}

.img-avatar {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.group-initials-text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    /* font-size: 1rem; */
    color: #475467;
    align-self: center;
    justify-self: center;
    display: flex;
    text-align: center;
    font-size: '0.625rem';
    background: #F2F4F7;
}

.hidden-avatars {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: -8px;
    background-color: #F2F4F7;
    border: 2px solid #FFFFFF;
    color: #475467;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 1.125rem;
}