

.avatar {
    display: flex;
    background: #F2F4F7;
    border-radius: 12.5rem;
    flex: none;
    /* order: 0; */
    flex-grow: 0;
    justify-content: center;
    justify-items: center;
}
.initials-text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    /* font-size: 1rem; */
    color: #475467;
    align-self: center;
    justify-self: center;
    display: flex;
    text-align: center;
}