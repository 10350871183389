.agency-selector-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    padding-top: 6rem; 
    cursor: default;
}

.agency-selector-list-agency{
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
    width: 20rem;
    color: #475467;
    margin-top: 0.5rem;
    margin-bottom: 1.25rem;
}