.pagination-body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.75rem 1.5rem 1rem;
    gap: 0.6875rem;
    max-height: 64px;
    border-top: 1px solid #EAECF0;
}

.pagination-details {
    display: flex;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #344054;
}

.pagination-actions {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 12px;
}