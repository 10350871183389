.agency-configurations-container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding-bottom: 3rem;
}

.agency-configurations-header {
    display: flex;
    flex-direction: column;
    padding: 1.5rem 2rem 0rem;
    gap: 1.5rem;
}

.agency-configurations-page-header {
    display: flex;
    flex-direction: column;
    padding: 0rem;
    gap: 1.25rem;
}

.agency-configurations-tabs {
    display: flex;
    padding: 0rem;
    border-bottom: 0.0625rem solid #EAECF0;
}

.agency-configurations-body {
    display: flex;
    flex-direction: column;
    padding: 0rem 2rem;
}

.agency-configurations-form {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
}

.agency-configurations-form-row {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    width: 17.5rem;
}

.agency-configurations-form-text-container {
    display: flex;
    flex-direction: column;
    min-width: 17.5rem;
}

.agency-configurations-row-text {
    display: flex;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #344054;
}

.agency-configurations-input-button-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.agency-configurations-input-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.625rem 0.875rem;
    gap: 0.5rem;
    background: #F9FAFB;
    border-width: 0.0625rem 0rem 0.0625rem 0.0625rem;
    border-style: solid;
    border-color: #D0D5DD;
    border-radius: 0.5rem 0rem 0rem 0.5rem;
    width: 25.75rem;
    height: 100%;
}

.agency-configurations-input-text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #667085;
}

.agency-configurations-copy-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.625rem 0.875rem;
    gap: 0.5rem;
    width: 6.5625rem;
    background: #FFFFFF;
    border-width: 0.0625rem;
    border-style: solid;
    border-color: #D0D5DD;
    border-radius: 0rem 0.5rem 0.5rem 0rem;
    cursor: pointer;
}

.agency-configurations-copy-button:active {
    background: #F9FAFB;
}

.agency-configurations-copy-text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #344054;
}

.agency-configurations-form-delete-row {
    display: flex;
    flex-direction: row;
    padding: 0.75rem;
    background: #FEF3F2;
    border-radius: 0.375rem;
}

.agency-configurations-delete-warning-text {
    display: flex;
    flex-direction: column;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #475467;
}

.agency-configurations-form-delete-input-text {
    display: flex;
    flex-direction: column;
    gap: 0.375rem;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #475467;
}

.agency-configurations-delete-button {
    display: flex;
    height: 40px;
}

.agency-configurations-button-group {
    display: flex;
    flex-direction: row;
    gap: 0.75rem;
    justify-content: flex-end;
}