.notifications-container {
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-columns: 1fr;
    grid-template-rows: minmax(5.875rem, 7.875rem) minmax(0px, 1.73fr);
    gap: 2rem 0px;
    width: 100%;
    height: 100%;
    background: #FCFCFD;
}

.notifications-header-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 5.875rem;
    gap: 1.5rem;
    padding: 2rem 2rem 0rem;
}

.notifications-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.notifications-tabs {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0rem;
    gap: 0.5rem;
    border-bottom: 0.0625rem solid #EAECF0;
    width: 100%;
}

.notifications-column {
    display: flex;
    flex-direction: column;
    padding: 0px 32px;
    gap: 20px;
}

.notifications-column-header {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.notifications-main-text {
    display: flex;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #101828;
}

.notifications-supporting-text {
    display: flex;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #475467;
}

.notifications-main-body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 20px;
}

.notifications-row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 64px;
}

.notifications-body-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 4px;
}

.notifications-text-and-icon {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 12px;
}

.notifications-body-main-text {
    display: flex;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #344054;
}

.notifications-body-supporting-text {
    font-family: 'Inter';
    width: 400px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #475467;
}

.manage-button-container {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 96px;
}

.notifications-manage-button {
    display: flex;
    align-items: center;
    justify-content: center;
}

.notifications-status-badge-enabled {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 2px 8px 2px 6px;
    gap: 4px;
    background: #ECFDF3;
    mix-blend-mode: multiply;
    border-radius: 16px;
    margin: auto;
}

.status-text-enabled {
    display : flex;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #027A48;
}

.notifications-coming-soon-badge {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 2px 8px;
    border: 1.5px solid #475467;
    border-radius: 16px;
}

.notifications-status-badge-error {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 2px 8px 2px 6px;
    gap: 4px;
    background: #FFF4ED;
    mix-blend-mode: multiply;
    border-radius: 16px;
    margin: auto;
}

.status-text-error {
    display : flex;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #B93815;
}

.notifications-coming-soon-text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #344054;
}

/* CSS styling for Email Toggle ON or OFF Button */
.switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
    margin-bottom: 3px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}
  
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .2s;
    border-radius: 20px;
}
  
.slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    transition: .2s;
    border-radius: 50%;
}
  
input:checked + .slider {
    background-color: #365A6D;
}
  
input:focus + .slider {
    box-shadow: 0 0 1px #365A6D;
}
  
input:checked + .slider:before {
    transform: translateX(20px);
}
  