.menupopup-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0rem;
    height: 100%;
    background: #FFFFFF;
    flex: none;
    order: 1;
    flex-grow: 0;
    border-radius: 0.375rem;
    border-top: 0.063rem solid #EAECF0;
    border-right: 0.063rem solid #EAECF0;
    border-left: 0.063rem solid #EAECF0;
}
.menupopup-item-container {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0.938rem 1rem 0.938rem 1rem;
    height: 3.125rem;
    border-bottom: 0.063rem solid #EAECF0;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}
.menupopup-item-container-without-bottomborder {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0.938rem 1rem 0.938rem 1rem;
    height: 3.125rem;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}
.nav-justify-menupopup {
    display: flex;
    flex-direction: row;
    gap: 0.938rem;
    align-items: center;
}
.menupopup-text {
    width: 11.5rem;
    height: 1.25rem;
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #344054;
    flex: none;
    order: 1;
    flex-grow: 1;
}