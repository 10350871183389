.upload-container {

    display: flex;
    /* align-items: center; */
    width: 100%;
    flex-direction: column;
    gap: 0.375rem;
}
.upload-image-text {
    width: 380px;
    height: 18px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #475467;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}
.upload-text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 4px;
    width: 380px;
    height: 42px;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}
.upload-text-line {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding: 0px;
    gap: 4px;
    width: 380px;
    height: 20px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}
.upload-image-text2 {
    width: 112px;
    height: 20px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #475467;
    flex: none;
    order: 1;
    flex-grow: 0;
}
.upload-image-text1 {
    width: 101px;
    height: 20px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #26404E;
    flex: none;
    order: 1;
    flex-grow: 0;
}
.settings-upload-photo {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px 24px;
    gap: 4px;
    width: 428px;
    height: 126px;
    background: #FFFFFF;
    border-radius: 12px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    cursor: pointer;
}
.settings-comments {
    /* width: 112px; */
    /* height: 20px; */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #475467;
    flex: none;
    order: 1;
    flex-grow: 0;
}