.agency-inventory-container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding-bottom: 3rem;
}

.agency-inventory-header {
    display: flex;
    flex-direction: column;
    padding: 1.5rem 2rem 0rem;
    gap: 1.5rem;
}

.agency-inventory-page-header {
    display: flex;
    flex-direction: column;
    padding: 0rem;
    gap: 1.25rem;
}

.agency-inventory-tabs {
    display: flex;
    padding: 0rem;
    border-bottom: 0.0625rem solid #EAECF0;
}

.agency-inventory-body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px 32px;
    gap: 24px;
}

.agency-inventory-body-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    gap: 24px;
}

.agency-inventory-body-rows {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    width: 100%;
    gap: 20px;
}

.agency-inventory-content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 64px;
}

.agency-inventory-text-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    width: 400px;
    gap: 4px;
}

.agency-inventory-main-text {
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #344054;
}

.agency-inventory-supporting-text {
    display: flex;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #475467;
}

.agency-inventory-button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 4px;
}

.agency-inventory-badge {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2px 8px;
    background: #D0E0E8;
    border-radius: 16px;
    order: 2;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #26404E;
}

.agency-inventory-coming-soon-badge {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 2px 8px;
    border: 1.5px solid #475467;
    border-radius: 16px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #344054;
}