.connectivity-container {
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-columns: 1fr;
    grid-template-rows: minmax(0px, 4rem) minmax(0px, 7.625rem) minmax(0px, 1fr);
    gap: 1.5rem 0px;
    height: 100%;
    width: 100%;
}
.connectivity-header {
    padding: 0rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
}

.connectivity-body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: #FFFFFF;
    margin: 0rem 2rem 3rem;
    /* Gray/200 */
    border: 0.0625rem solid #EAECF0;
    /* Shadow/sm */
    box-shadow: 0rem 0.0625rem 0.1875rem rgba(16, 24, 40, 0.1), 0rem 0.0625rem 0.125rem rgba(16, 24, 40, 0.06);
    border-radius: 0.75rem;
}
.connectivity-tabs {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0rem;
    gap: 0.5rem;
    border-bottom: 0.0625rem solid #EAECF0;
    width: 100%;
}

.connectivity-body-filter {
    display: flex;
    padding: 0.75rem 1rem;
    width: calc(100% - 2rem);
    justify-content: space-between;
    border-bottom: 0.0625rem solid #EAECF0;
}

.connectivity-bulk-button-container {
    display: flex;
}

.connectivity-input-filter-button {
    display: flex;
    gap: 0.75rem;
}

.connectivity-body-empty {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 22rem;
    margin: auto;
    gap: 1.25rem;
}

.connectivity-body-text-loading {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    gap: 0.5rem;
    overflow-y: auto;
    justify-content: space-around;
}

.connectivity-body-text {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    gap: 0.5rem;
    overflow-y: auto;
    justify-content: space-between;
}