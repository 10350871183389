.signup-row {
    display: flex;
    flex-direction: row;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}

.signup-column {
    display: flex;
    flex-direction: column; 
    justify-content: space-between;
}

.signup-image {
    display: flex;
    width: 100%;
    /* background: #F2F4F7; */
    background-image: url('../login//img/loginimage.svg');
    background-position: center;
    background-size: cover;
}

.signup-wrapper {
    display: flex;
    flex-direction: column;
    gap: 8rem;
}

.signup-text-background {
    padding: 1.25rem;
    background: rgba(255, 255, 255, 0.6);
    -webkit-backdrop-filter: blur(0.188rem);
    backdrop-filter: blur(0.188rem);
    border-radius: 0.5rem;
    flex: none;
    order: 0;
    flex-grow: 1;
}

.signup-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 2rem;
    gap: 0.25rem;
}

.signup-logo {
    width: 8.6875rem;
    height: 2rem;
}

.signup-logo-wrapper {
    display: flex;
    justify-content: flex-start;
    width: 100%;
}

.signup-logo-alt {
    width: 6.75rem;
    height: auto;
}

.signup-logo-mobile-alt {
    width: 7.25rem;
    height: auto;
    padding-bottom: 1.25rem;
}

.signup-container {
    display: flex;
    flex-direction: column;
    padding: 0rem 8rem;
    box-sizing: border-box;
    width: 38.5rem;
    height: auto;
    gap: 2rem;
}

.signup-form {
    display: flex;
    flex-direction: column;
    padding: 0rem;
    gap: 1.5rem;
}

.signup-inputname-row{
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 1.5rem;
}

.signup-password-form {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
}

.signup-password-input {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.signup-password-strength-container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
}

.signup-password-error {
    width: 24.375rem;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    color: #F04438;
    flex: none;
    flex-grow: 0;
    cursor: default;
}

.signupWrapperBar {
    flex: none;
    flex-grow: 0;
}

:root {
    --strength-bar-width: 0vw;
}

.signupContainerBar {
    background-color: #EAECF0;
    height: 0.5rem;
    border-radius: 0.313rem;
}

.signupBarCompletedBar {
    background-color: #365A6D;
    width: var(--strength-bar-width);
    height: 0.5rem;
    border-radius: 0.313rem;
    transition: width 1s ease-in-out 0s;
}

.signup-footer {
    display: flex;
    padding: 2rem;
    height: auto;
}

.signup-text-container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 3.5rem 4rem 0rem 4rem;
}

.powered-text {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 5.875rem;
    height: 1.5rem;
}

.signup-text {
    padding-bottom: 1rem;
    font-style: normal;
    font-weight: 500;
    font-size: 1.875rem;
    line-height: 2.375rem;
    color: #101828;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 1;
    cursor: default;
}

.signup-horizon-image {
    display: flex;
}

.horizon-test-image-class {
    border: 6px solid #101828;
    border-bottom: none;
    border-right: none;
    border-top-left-radius: 12px;
    display: flex;
    height: 100%;
    background-image: url("./img/horizon.svg");
    background-size: cover;
}

.signUp-mainMobile {
    overflow: hidden;
}

.signUp-row-mobile {
    display: flex;
    max-width: 1224px;
    height: 100%;
    box-sizing: border-box;
    padding: 3rem 1rem 3rem 1rem;
}

.signUp-container-mobile {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
}

.signUp-header-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    gap: 2rem;
}

.signUp-header-mobile {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
}

.signUp-header-content-mobile {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.signUp-content-mobile {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1.5rem;
}

.signUp-form-mobile {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 1.25rem;
}

.signUp-footer-container {
    display: flex;
    margin: auto;
}

.signUp-footer-mobile {
    display: flex;
}