.input-text-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.625rem 0.875rem;
    gap: 0.5rem;
    width: 20.625rem;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #667085;
    flex: none;
    order: 0;
    flex-grow: 0;
    border-radius: 0.5rem;
    border: 0.063rem solid #D0D5DD;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
}