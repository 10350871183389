.forgot-password-container-main{
    display: flex;
    justify-content: center;
    width: 100vw;
}
.forgot-password-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 6rem; 
    gap: 2rem;
    align-self: center;
}
.agency-selector-list-agency{
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
    width: 20rem;
    color: #475467;
    margin-top: 0.5rem;
    margin-bottom: 1.25rem;
}
.text-button-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: baseline;
    gap: 0.25rem;
    width: 22.5rem;
    flex: none;
    align-self: stretch;
    flex-grow: 0;
}
.reset-new-password-column{
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    width: 100%;
}
.reset-password-input-container {
    display: flex;
    flex-direction: column;
    gap: 0.375rem;
}
.wrapperBar {
    flex: none;
    flex-grow: 0;
    padding-top: 1.25rem;
}
.containerBar {
    background-color: #EAECF0;
    height: 0.5rem;
    border-radius: 0.313rem;
}
:root {
    --strength-bar-width: 0vw;
}
.barCompletedBar {
    background-color: #365A6D;
    width: var(--strength-bar-width);
    height: 0.5rem;
    border-radius: 0.313rem;
    transition: width 1s ease-in-out 0s;
}

.forgotPW-mainMobile {
    overflow: hidden;
}

.forgot-password-container-main-mobile {
    display: flex;
    height: 100%;
    box-sizing: border-box;
    padding: 3rem 1rem 3rem 1rem;
}

.forgot-password-content-mobile {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    width: 100%;
}