.empty-state-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    width: 22rem;
    /* gap: 1.25rem; */
}

.empty-state-text {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    gap: 0.5rem;
    overflow-y: auto;
    justify-content: space-between;
    margin-top: 1.25rem;
}
.empty-state-button{
    margin-top: 2rem;
}