.asset-card-detail-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    right: 4rem;
    margin-top: 2rem;
    padding: 1.5rem 0rem 0rem 0rem;
    width: 24.25rem;
    background-color: #FFFFFF;
    border: 0.063rem solid #EAECF0;
    /* Shadow/sm */
    box-shadow: 0rem 0.063rem 0.188rem rgba(16, 24, 40, 0.1), 0rem 0.063rem 0.125rem rgba(16, 24, 40, 0.06);
    border-radius: 0.75rem;
    z-index: 2;
}

.asset-card-detail-container-mobile {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    margin-top: 2rem;
    /* padding: 1.5rem 0rem 0rem 0rem; */
    width: 100%;
    background-color: #FFFFFF;
    /* border: 0.063rem solid #EAECF0; */
    /* Shadow/sm */
    /* box-shadow: 0rem 0.063rem 0.188rem rgba(16, 24, 40, 0.1), 0rem 0.063rem 0.125rem rgba(16, 24, 40, 0.06); */
    /* border-radius: 0.75rem; */
    z-index: 2;
    box-sizing: border-box;
    padding-bottom: 16px;
}

.asset-card-device-container {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

/* first header container */
.asset-card-header-container {
    display: flex;
    width: 100%;
    box-sizing: border-box;
    flex-direction: column;
    gap: 0.25rem;
    border-bottom: solid 0.063rem #EAECF0;
    padding: 0rem 1rem 0.75rem 1rem;
}

.asset-card-header-container-no-telemetry{
    display: flex;
    width: 100%;
    box-sizing: border-box;
    flex-direction: column;
    gap: 0.25rem;
    padding: 0rem 1rem 0.75rem 1rem;
}

/* second and third content container */
.asset-card-content-container {
    display: flex;
    width: 100%;
    box-sizing: border-box;
    flex-direction: column;
    gap: 0.75rem;
    padding: 0.75rem 1rem;
    border-bottom: solid 0.063rem #EAECF0;
}

.asset-card-content-container-no-telemetry {
    display: flex;
    width: 100%;
    box-sizing: border-box;
    flex-direction: column;
    align-items: center;
    padding: 0rem 1rem 1.5rem 1rem;
}
.asset-card-text-no-telemetry {
    text-align: center;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
}
.asset-card-title-no-telemetry{
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5rem;
    margin-bottom: 0.25rem;
}
/* fourth content container */
.asset-card-buttons-container {
    display:flex;
    gap: 1rem;
    justify-content: center;
    width: 100%;
    box-sizing: border-box;
    padding: 1rem 0rem;
}

.asset-card-signal-container {
    display: flex;
    gap: 0.5rem;
}
.asset-card-refresh-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 0.875rem;
    gap: 0.5rem;
}
.asset-card-address-container {
    display: flex;
    gap: 1rem;
    align-items: flex-start;
}
.asset-card-address-text {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
}
.asset-card-address-text-main {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}
.asset-card-get-data-text{
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #26404E
}
.asset-card-geolocation-container {
    display:flex;
    gap: 1rem;
}
.asset-card-lat-lon-text {
    text-decoration: none;
    font-size: 0.75rem;
    cursor: pointer;
    color: #1570EF;
}
.asset-card-info-detail {
    width: 50%;
    display: flex;
    gap: 1rem;
    align-items: flex-start;
    /* flex: 2 1; */
}
.asset-card-close-button {
    position: absolute;
    right: 1.25rem;
}
.asset-card-header-text {
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.75rem;
    color: #365A6D;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 12.5rem;
}

.asset-card-empty-header-text {
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.75rem;
    color: #365A6D;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 17.5rem;
}

.asset-device-detail-container {
    display: flex;
    font-size: 0.75rem;
    line-height: 1.125rem;
    font-weight: 400;
    color: #475467;
}

.asset-card-time-container {
    margin-top: 0.75rem;
    display: flex;
    align-items: center;
    gap: 1rem;
}

.asset-card-detail-text {
    font-weight: 500;
    display: flex;
    align-items: center;
    font-size: 0.75rem;
    line-height: 1.125rem;
    color: #344054;
    gap: 0.25rem;
}

.asset-card-button-no-telemetry{
    display: flex;
    gap: 0.75rem;
    width: 100%;
    justify-content: center;
    padding: 0rem 0rem 1.5rem 0rem;
}

.asset-card-last-seen-container {
    display: flex;
    width: max-content;
    gap: 0.313rem;
    padding: 0.125rem 0.5rem;
    background: #FFFAEB;
    border-radius: 2rem;
}

.asset-card-last-seen-text {
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 1.125rem;
    color: #B54708;
}

.geo-description {
    position: absolute;
    background-color: #475467;
    border: 1px solid #ccc;
    padding: 2px 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 10;
    font-size: 0.7rem !important;
    border-radius: 20px;
    white-space: nowrap;
    color: #fff;
    width: auto;
    min-width: 100px;
    text-align: center;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.3s linear;
}

.geo-detail {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.geo-detail:hover .geo-description {
    visibility: visible;
    opacity: 1;
    top: -25%;
    left: 100%;
    margin-left: 5px;
}