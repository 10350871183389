.teams-container {
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-columns: 1fr;
    grid-template-rows: minmax(5.875rem, 7.875rem) minmax(0px, 1.73fr);
    gap: 2rem 0px;
    width: 100%;
    height: 100%;
    background: #FCFCFD;
}

.teams-header-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 5.875rem;
    gap: 1.5rem;
    padding: 2rem 2rem 0rem;
}

.teams-title-header {
    display: flex;
    padding: 0rem 2rem;
}

.teams-tabs {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0rem;
    gap: 0.5rem;
    border-bottom: 0.0625rem solid #EAECF0;
    width: 100%;
}

.teams-body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* background: #FFFFFF; */
    margin: 0rem 2rem 3rem;
    border: 0.0625rem solid #EAECF0;
    box-shadow: 0rem 0.0625rem 0.1875rem rgba(16, 24, 40, 0.1), 0rem 0.0625rem 0.125rem rgba(16, 24, 40, 0.06);
    border-radius: 0.75rem;
}

.teams-table-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px 24px;
    gap: 1rem;
    width: calc(100% - 3rem);
    /* background: #FFFFFF; */
    border-bottom: 0.0625rem solid #EAECF0;
}

.teams-table-header-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0rem;
    gap: 0.25rem;
}

.teams-table-title {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
}

.header-text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.75rem;
    color: #101828;
}

.member-count {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.125rem 0.5rem;
    background: #D0E0E8;
    mix-blend-mode: multiply;
    border-radius: 1rem;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 1.125rem;
    text-align: center;
    color: #26404E;
}

.teams-table-subtitle {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #475467;
}

.teams-table-button-group {
    display: flex;
    gap: 0.75rem;
    margin-top: auto;
    margin-bottom: auto;
}

.teams-table-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    overflow-y: auto;
}

.teams-table-container-loading {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    background-color: #FFFFFF;
}

.users-container-outline {
    display: flex;
    box-sizing: border-box;
    width: 48px;
    height: 48px;
    /* Base/White */
    background: #FFFFFF;
    /* Gray/200 */
    border: 1px solid #EAECF0;
    /* Shadow/xs */
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 10px;
}