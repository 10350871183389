.teams-pagination-body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.75rem 1.5rem 1rem;
    gap: 0.6875rem;
    max-height: 64px;
    border-top: 1px solid #EAECF0;
    border-bottom-left-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;
    background: #FFFFFF;
}

.pagination-routes {
    display: flex;
}