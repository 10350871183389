.header-container-main {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5rem;
    flex: none;
    order: 0;
    flex-grow: 0;
    align-self: stretch;
}

.header-nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.25rem;
    flex: none;
    align-self: stretch;
    flex-grow: 0;
    /* padding: 0rem 1rem 0rem 1rem; */
}

.main-nav-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0rem 1rem;
    gap: 1.5rem;
    flex: none;
    order: 0;
    flex-grow: 0;
    align-self: stretch;
}

.main-mobile-nav-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    gap: 1.5rem;
    flex: none;
    order: 0;
    flex-grow: 0;
    align-self: stretch;
}

.header-image-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 2rem;
    flex: none;
    order: 0;
    flex-grow: 0;
    padding: 2rem 1.5rem 0rem 1.5rem;
}

.search-sidebar-header {
    padding: 0rem 1.5rem 0rem 1.5rem;
    /* width: 12.75rem, */
}

.logo-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 8.938rem;
    height: 2rem;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.logo-container-alt {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 8.938rem;
    height: 2rem;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin-top: 0.5rem;
}

.logo-alt {
    width: 80.5%;
    height: auto;
}

.logo {
    width: 9.375rem;
    height: 2.188rem;
    flex: none;
    order: 0;
    flex-grow: 0;
}