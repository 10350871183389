.badge-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.125rem 0.5rem 0.125rem 0.375rem;
    gap: 0.25rem;
    height: 1.375rem;
    mix-blend-mode: multiply;
    border-radius: 1rem;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}
.badge-text {
    width: 9.563rem;
    height: 1.125rem;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 1.125rem;
    text-align: center;
    flex: none;
    order: 1;
    flex-grow: 0;
}
.badge-icon {
    width: 0.75rem;
    height: 0.75rem;
    flex: none;
    order: 0;
    flex-grow: 0;
}