.asset-performance-container {
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-columns: 1fr;
    grid-template-rows: minmax(0px, 4rem) minmax(0px, 7.625rem) minmax(0px, 1fr);
    gap: 1.5rem 0px;
    height: 100%;
    width: 100%;
}

.asset-performance-header {
    padding: 0rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
}

.asset-performance-tabs {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0rem;
    gap: 0.5rem;
    border-bottom: 0.0625rem solid #EAECF0;
    width: 100%;
}

.asset-performance-body {
    padding: 0rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.asset-performance-body-title {
    display: flex;
    flex-direction: row;
    gap: 1.5rem;
    padding-bottom: 1.25rem;
    border-bottom: 0.0625rem solid #EAECF0;
}

.asset-performance-button-group {
    display: flex;
    flex-direction: row;
    gap: 12px;
}

.asset-performance-body-form {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.timeline-container {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    padding-bottom: 20px;
    border-bottom: 0.0625rem solid #EAECF0;
    width: 100%;
}

.timeline-title-subtitle-container {
    display: flex;
    flex-direction: column;
    width: 17.5rem;
}

.timeline-text {
    width: 17.5rem;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */
    /* Gray/700 */
    color: #344054;
}

.timeline-subtitle {
    font-family: 'Inter';
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #475467;
}

.asset-device-container {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    padding: 20px 0px 24px;
    border-bottom: 0.0625rem solid #EAECF0;
}