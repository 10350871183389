.connectivity-cell {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
}

.connectivity-device-icon {
    display: flex;
    align-items: center;
}

.connectivity-table-text {
    display: flex;
    align-items: center;
    font: 'Inter';
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #475467;
}