.notification-feed-container {
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    padding: 1.5rem;
    gap: 1.5rem;
    width: 25rem;
    height: 810px;
    overflow: scroll;
}

.notification-feed-header {
    display: flex;
    box-sizing: border-box;
    font-family: Inter;
    font-size: 1.25rem;
    line-height: 1.875rem;
    font-weight: 600;
    color: #101828;
}

.notification-feed-content {
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    gap: 1rem;
}

.notification-feed-item {
    display: flex;
    align-items: flex-start;
    box-sizing: border-box;
    flex-direction: row;
    gap: 0.75rem;
}

.notification-feed-item-content {
    display: flex;
    position: relative;
    box-sizing: border-box;
    flex-direction: column;
    gap: 0.75rem;
}

.notification-feed-item-container {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
}

.notification-feed-item-header {
    display: flex;
    gap: 0.5rem;
}

.notification-feed-item-body {
    display: flex;
}

.notification-feed-item-type {
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    color: #344054;
    font-style: Inter;
}

.notification-feed-item-timestamp {
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    color: #475467;
    font-style: Inter;
}

.notification-feed-item-body {
    display: flex;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: #475467;
    font-style: Inter;
}

.unread-icon-feed-item {
    position: absolute;
    right: 0;
    height: 0.5rem;
    width: 0.5rem;
}

.notification-feed-empty-state {
    display: flex;
    box-sizing: border-box;
    align-items: center;
    flex-direction: column;
    gap: 1.25rem;
}

.notification-feed-empty-body {
    display: flex;
    box-sizing: border-box;
    gap: 8px;
    flex-direction: column;
    align-items: center;
}

.notification-feed-empty-header {
    font-size: 18px;
    line-height: 18px;
    font-weight: 600;
    color: #101828;
    font-style: Inter;
}

.notification-feed-empty-subtext {
    font-size: 16px;
    line-height: 16px;
    font-weight: 400;
    color: #475467;
    font-style: Inter;
}