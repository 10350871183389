.agencyName-container {
    display: flex;
    flex-direction: column;
}

.agencyName-text {
    display: flex;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #101828;
}

.agencyId-text {
    display: flex;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #475467;
}

.totalDevices-badge {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #F2F4F7;
    border-radius: 1rem;
    padding: 0.125rem 0.5rem;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 1.125rem;
    text-align: center;
    color: #344054;
}

.status-badge {
    display: flex;
    border-radius: 1rem;
    padding: 0.125rem 0.5rem;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 1.125rem;
    text-align: center;
}

.agenciesLastLogin-container {
    display: flex;
    flex-direction: column;
}

.agenciesLastLogin-date {
    display: flex;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #101828;
}

.agenciesLastLogin-time {
    display: flex;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #475467;
}