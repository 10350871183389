    .selection-wrapper {
      position: relative;
      height: auto;
      /* justify-content: center; */
      /* padding-top: 1.25rem; */
    }

    .selections-container {
      width: 22.5rem;
    }

    .selection-button {
      border: 1px solid #D0D5DD;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 0.5rem;
      /* height: 44px; */
      background: #FFFFFF;
      /* width: 100%; */

      height: 22px;
      /* temporary */
      /* width: 332px; */
      /* temporary */
      line-height: 22px;

      padding: 0.625rem 0.75rem;
      margin-bottom: 3.4px;
      position: relative;
      text-align: left;
      display: flex;
      gap: 0.5rem;
      align-items: center;

      resize: none;
    }

    .selection-button::placeholder {
      font-family: Inter;
      color: #D0D5DD;
      line-height: 22px;
    }
/* 
    .selection-button:hover {
      cursor: pointer;
    } */

    .selection-button::after {
      position: absolute;
      right: 6px;
      top: 9.8px;
      content: url(./img/chevronDown.svg);
    }

    .selection-button.expanded {
      border: 1px solid #73A2BA;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #F4EBFF;
    }

    .selection-button.expanded::after {
      content: url(./img/chevronDown.svg);
    }

  .selection-button:focus{
    outline: none;
  }
  
  ul.options {
    border: 1px solid #EAECF0;
    /* Shadow/lg */
  
    box-shadow: 0rem 0.75rem 1rem -0.25rem rgba(16, 24, 40, 0.08), 0rem 0.25rem    0.375rem -0.125rem rgba(16, 24, 40, 0.03);
    border-radius: 0.5rem;
    display: none;
    max-height: 18rem;
    list-style: none;
    padding: 0.25rem 0.25rem;
    margin-top: 1px;
    position: absolute;
    background: #FFFFFF;
    width: 100%;
    box-sizing: border-box;
    z-index: 3;
    overflow: auto;
  }
  
  ul.options::-webkit-scrollbar {
    width: 1rem;
  }

    ul.options::-webkit-scrollbar-thumb {
      border: 4px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
      border-radius: 9999px;
      background-color: #EAECF0;
    }

    ul.show {
      display: block;
    }

    ul.options li {
      padding: 0.375rem 0.625rem;
      margin-bottom: 0.25rem;
    }

    ul.options li:active,
    ul.options li:focus,
    ul.options li:hover,
    ul.options li[aria-selected="true"] {
      background: #F9FAFB;
      cursor: pointer;
    }

    .list-agency {
      font-weight: 500;
      font-size: 1rem;
      line-height: 1.5rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 11.25rem;
    }

    .list-agency-noWrap {
      font-weight: 500;
      font-size: 1rem;
      line-height: 1.5rem;
    }

    .list-agency-asset {
      font-weight: 500;
      font-size: 1rem;
      line-height: 1.5rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      /* max-width: 15rem; */
    }

    .list-asset-id {
      font-weight: 400;
      font-size: 1rem;
      /* line-height: 1.25rem; */
      color: #475467;
      position: relative;
      top: 0.188rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      /* padding: 0px 8px 0px 0px; */
      /* max-width: 90%; */
    }

    .list-agency-id {
      font-weight: 400;
      font-size: 1rem;
      /* line-height: 1.25rem; */
      color: #475467;
      position: relative;
      top: 0.188rem;
      /* padding: 0px 8px 0px 0px; */
    }

    .btn-list-placeholder {
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.25rem;
      color: #667085;
      width: 100%
    }
/* 
    .btn-list-selected-textfield {
      display: flex;
    gap: 8px;
      display: inline-grid;
    grid-auto-flow: column;
    max-width: 100%;
    grid-auto-columns: 1fr;
    gap: '8px';
    } */

    .btn-list-placeholder:focus {
      outline: none;
    }

    .btn-list-selected-textfield:focus {
      outline: none;
    }

    .btn-list-agency-name {
      font-weight: 500;
      font-size: 1rem;
      line-height: 1.5rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 13.25rem;
    }

    .btn-list-agency-name-noWrap {
      font-weight: 500;
      font-size: 1rem;
      line-height: 1.5rem;
    }

    .btn-list-agency-name-noWrap:focus {
      outline: none;
    }

    .btn-list-agency-name:focus {
      outline: none;
    }

    .btn-list-asset-id {
      /* font-weight: 400;
      line-height: 1.5rem;
      color: #475467;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 90%; */
      font-weight: 400;
      font-size: 1rem;
      color: #475467;
      top: 0.188rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    
    .btn-list-asset-id:focus {
      outline: none;
    }
    
    .btn-list-agency-id {
      font-weight: 400;
      line-height: 1.5rem;
      color: #475467;
    }

    .btn-list-agency-id:focus {
      outline: none;
    }

    .btn-list-container:focus {
      outline: none;
    }

    .btn-list-container {
      display: flex;
      align-items: center;
      height: 22px;
      gap: 8px;
    }