.assetStatus-rows {
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-columns: 1fr;
    grid-template-rows: 2.75rem 2.625rem 2.625rem 2.625rem 2.625rem;
    gap: 0.5rem;
}

.assetStatus-count {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 2.25rem;
    line-height: 2.75rem;
    padding-left: 0.5rem;
}

.assetStatus {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #344054;
}

.assetStatus-inMotion {
    display: grid;
    grid-template-columns: 2.5rem 1fr;
    grid-template-rows: 1fr;
    gap: 0.5rem;
}

.assetStatus-inMotion-progress {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 0.25rem;

}