.analytics-container {
    display: flex;
    width: 100%;
    gap: 1.5rem;
    flex-direction: column;
}

.analytics-header {
    display: flex;
    width: 100%;
    padding: 0rem 2rem;
    gap: 1.5rem;
    box-sizing: border-box;
    flex-direction: column;
}

.analytics-config-tab {
    display: flex;
    width: 100%;
    border-bottom: 0.0625rem solid #EAECF0;
}

.analytics-body {
    display: flex;
    padding: 0rem 2rem;
    width: 100%;
    gap: 1.5rem;
    box-sizing: border-box;
    flex-direction: column;
}

.analytics-body-title {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-direction: row;
    padding-bottom: 1.25rem;
    border-bottom: 0.0625rem solid #EAECF0;
}

.anayltics-title-button-group {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
}

.anayltics-body-form {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.analytics-timeline-container {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    padding-bottom: 1.25rem;
    border-bottom: 0.0625rem solid #EAECF0;
    width: 100%;
}

.analytics-timeline-text-container {
    display: flex;
    flex-direction: column;
    width: 17.5rem;
}


.analytics-timeline-text {
    width: 17.5rem;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */
    /* Gray/700 */
    color: #344054;
}

.analytics-asset-device-container {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    padding: 20px 0px 24px;
    border-bottom: 0.0625rem solid #EAECF0;
}

