.telemetry-log-container {
    /* display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px 32px 48px;
    gap: 24px; */
    /* height: 960px; */
    /* width: 1160px; */
    display: grid; 
    grid-template-columns: 1fr; 
    grid-template-rows: minmax(14rem, 14rem) minmax(0px, 1fr); 
    gap: 24px 0px;
    width: 100%;
    height: 100%;
}

.telemetry-log-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px 32px 0px;
    gap: 24px;
    /* height: 224px; */
}

.telemetry-log-title-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.telemetry-log-details {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 20px 24px;
    gap: 56px;
    height: auto;
    width: calc(100% - 48px);
    background: #F9FAFB;
    border-radius: 12px;
}

.telemetry-log-label {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    width: 496px;
    height: auto;
}

.telemetry-log-asset-device {
    display: flex;
    gap: 3px;
}

.telemetry-log-name-text {
    display: flex;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;
    color: #344054;
}

.telemetry-log-text {
    display: flex;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #344054;
}

.telemetry-log-table {
    display: flex;
    margin: 0px 32px 48px;
    border: 1px solid #EAECF0;
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
    border-radius: 12px;
}

.telemetry-notification-body {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.telemetry-progress-bar {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.telemetry-progress-bar-text {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}