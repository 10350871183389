.account-container {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 1.5rem 0.5rem 0rem 0.5rem;
    isolation: isolate;
    height: 4rem;
    border-top: 0.063rem solid #EAECF0;
    flex: none;
    order: 2;
    flex-grow: 0;
    gap: 4.688rem;
    cursor: default;
    width: 100%;
}
.avatar-label-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0rem;
    gap: 0.75rem;
    height: 2.5rem;
    flex: none;
    order: 0;
    flex-grow: 1;
    z-index: 0;
}
.avatar {
    display: flex;
    width: 2.5rem;
    height: 2.5rem;
    background: #F2F4F7;
    border-radius: 12.5rem;
    flex: none;
    order: 0;
    flex-grow: 0;
    justify-content: center;
    justify-items: center;
}
.user {
    position: relative;
    left: 20%;
    right: 20%;
    top: 25%;
    bottom: 20%;
}
.avatar-icon {
    position: relative;
    left: 8%;
    right: 10%;
    top: 8%;
    bottom: 10%;
}

.text-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0rem;
    width: 4.313rem;
    height: 2.25rem;
    flex: none;
    order: 1;
    flex-grow: 0;
}
.text-account {
    font-style: normal;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #344054;
    flex: none;
    order: 0;
    flex-grow: 0;
    overflow: hidden;;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 9rem;
    height: 1.25rem;
}
.supporting-text-account {
    font-style: normal;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1rem;
    color: #475467;
    flex: none;
    order: 1;
    flex-grow: 0;
    width: 4.313rem;
    height: 1rem;
}

.account-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
    gap: 0.5rem;
    position: relative;
    right: 0rem;
    top: 0rem;
    border-radius: 0.5rem;
    flex: none;
    order: 1;
    flex-grow: 0;
    z-index: 1;
    cursor: pointer;
}
.account-menu {
    /* width: 1.25rem; */
    height: 1.25rem;
    flex: none;
    order: 0;
    flex-grow: 0;
}
.initials-text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    color: #475467;
    align-self: center;
    justify-self: center;
    display: flex;
    text-align: center;
    line-height: 1.5rem;
}

