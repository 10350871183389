input[type="checkbox"] {
  /* -webkit-appearance: none; */
  appearance: none;
  background-color: #FFFFFF;
  margin: 0;
  font: inherit;
  /* width: 20px;
    height: 20px; */
  border: 1px solid #D0D5DD;
  border-radius: 6px;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
}

input[type="checkbox"]:checked {
  /* -webkit-appearance: none; */
  appearance: none;
  background-color: #D0E0E8;
  margin: 0;
  font: inherit;
  /* width: 20px;
    height: 20px; */
  border: 1px solid #365A6D;
  border-radius: 6px;
  transform: translateY(-0.075em);
  /* display: flex; */
  place-content: center;
}

input[type="checkbox"]::before {
  content: "";
  width: 14px;
  height: 14px;
  transform: scale(0);
}

input[type="checkbox"]:checked::before {
  /* clip-path: polygon(28% 47%, 41% 59%, 75% 30%, 83% 38%, 41% 74%, 21% 55%); */
  clip-path: path("M11.096 0.390037L3.93602 7.30004L2.03602 5.27004C1.68602 4.94004 1.13602 4.92004 0.736015 5.20004C0.346015 5.49004 0.236015 6.00004 0.476015 6.41004L2.72602 10.07C2.94602 10.41 3.32601 10.62 3.75601 10.62C4.16601 10.62 4.55602 10.41 4.77602 10.07C5.13602 9.60004 12.006 1.41004 12.006 1.41004C12.906 0.490037 11.816 -0.319963 11.096 0.380037V0.390037Z");
  transform: scale(1);
  margin: 7px 3px 4px 4px;
  ;
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1px 1px #365A6D;
  background-color: #365A6D;
}

input[type="checkbox"]:indeterminate {
  appearance: none;
  background-color: #D0E0E8;
  margin: 0;
  /* width: 20px;
  height: 20px; */
  border: 1px solid #365A6D;
  border-radius: 6px;
  display: grid;
  place-content: center;
}

input[type="checkbox"]:indeterminate::before {
  height: 2px;
  width: 8.17px;
  clip-path: inset(0% 0% 0% 0% round 30%);
  ;
  transform: scale(1.3);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1px 1px #365A6D;
  background-color: #365A6D;
}

input[type="checkbox"]:disabled {
  background: #F2F4F7;
  border-color: #D0D5DD;
}

input[type="checkbox"]:disabled:checked {
  background: #F2F4F7;
  border-color: #D0D5DD;
}


input[type="checkbox"]:disabled:checked::before {
  background: #D0D5DD;

}