.nav-container {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
}

.nav-container-color {
    display: flex;
    flex-direction: row;
    /* width: 0%; */
    height: 2.5rem;
    background: #FFFFFF;
    border-radius: 0.375rem;
    flex: none;
    order: 1;
    flex-grow: 0;
    padding: 0rem 0rem 0rem 1rem;
    justify-content: space-between;
    align-self: stretch;
    align-items: center;
    z-index: 2;
}

.nav-justify {
    display: flex;
    flex-direction: row;
    gap: 0.938rem;
    align-items: center;
}

.icon-nav-container {
    display: flex;
    width: 1.125rem;
    height: 1.125rem;
    flex: none;
    order: 1;
    flex-grow: 0;
    justify-content: center;
}

.icon-nav {
    left: 12.5%;
    right: 12.5%;
    top: 9.45%;
    bottom: 12.5%;
}

.text-nav {
    /* font-family: 'Inter'; */
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #344054;
    flex: none;
    order: 1;
    flex-grow: 0;
}