.access-privilege-title {
    display: flex;
    gap: 0.375rem;
}

.name-container {
    display: flex;
    flex-direction: row;
    gap: 0.75rem;
}

.name-avatar-container {
    display: flex;
}

.table-member-name-container {
    display: flex;
    flex-direction: column;
}

.access-privilige-cell {
    display: flex;
    gap: 0.25rem;
}

.access-privilege-owner-chip {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.125rem 0.5rem;
    background: #EFF8FF;
    mix-blend-mode: multiply;
    border-radius: 1rem;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 18px;
    text-align: center;
    color: #175CD3;
}

.access-privilege-regular-chip {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.125rem 0.5rem;
    background: #D0E0E8;
    mix-blend-mode: multiply;
    border-radius: 1rem;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 1.125rem;
    text-align: center;
    color: #26404E;
}

.access-privilege-modules {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.125rem 0.5rem 0.125rem 0.375rem;
    gap: 0.25rem;
    background: #F9FAFB;
    mix-blend-mode: multiply;
    border-radius: 1rem;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 1.125rem;
    text-align: center;
    color: #344054;
}

.access-privilege-tags {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.125rem 0.5rem 0.125rem 0.375rem;
    gap: 0.25rem;
    background: #F9FAFB;
    mix-blend-mode: multiply;
    border-radius: 1rem;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 1.125rem;
    text-align: center;
    color: #344054;
}

.enabled-chip {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 2px 8px;
    background: #ECFDF3;
    mix-blend-mode: multiply;
    border-radius: 16px;
}

.enabled-chip-text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #027A48;
}

.member-options-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1.7862rem;
    padding-right: 26px;
}

.member-delete {
    display: flex;
    cursor: pointer;
}

.member-delete:disabled {
    pointer-events: none;
    opacity: 0.7;
}

.member-edit {
    display: flex;
    cursor: pointer;
}

.member-edit:disabled {
    pointer-events: none;
    opacity: 0.7;
}

.MuiBadge-overlapCircular {
    z-index: 0 !important;
}