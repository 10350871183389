.panelNavigator-container{
    display: flex;
    display:inline-block;
    flex-direction: row;
    align-items: center;
    padding: 0.5rem 0.75rem;
    gap: 0.5rem;

    font-weight: 600;
    font-size: 1rem;
    color: #101828;
}