.update-main-content {
    display: flex;
    flex-direction: column;
    padding: 2rem 2rem 3rem;
    position: relative;
    height: calc(100% - 5rem);
    overflow: auto;
    gap: 2rem;
    background: #FCFCFD;
}

.update-back-button {
    display: flex;
}

.update-form {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 1.5rem;
}

.update-header {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
}

.update-title {
    display: flex;
    flex-direction: row;
}

.update-button-group {
    display: flex;
    flex-direction: row;
    gap: 0.75rem;
}

.update-member-details {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
}

.update-input-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    align-self: stretch;
    flex-grow: 1;
    border: 1px solid #D0D5DD;
    border-radius: 8px;
    background: #FFFFFF;
    height: 2.75rem;
}

.member-form-row {
    display: flex;
    flex-direction: row;
}

.member-input-label {
    display: flex;
    flex-direction: column;
    width: 17.5rem;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #344054;
}

.member-name-container {
    display: flex;
    flex-direction: row;
    gap: 1.5rem;
}

.member-name-field {
    display: flex;
    flex-direction: column;
    gap: 0.375rem;
}

.member-name-label {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #344054;
}

.member-input-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.625rem 0.875rem;
    gap: 0.5rem;
    width: 13.35rem;
    height: 1.5rem;
    background: #F9FAFB;
    border: 0.0625rem solid #D0D5DD;
    box-shadow: 0rem 0.0625rem 0.125rem rgba(16, 24, 40, 0.05);
    border-radius: 0.5rem;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #667085;
}

.member-email-container {
    display: flex;
}

.member-phone-number-container {
    display: flex;
    width: 32rem;
}

.member-access-privilege-container {
    display: flex;
}

.member-owner-access-privilege-container {
    display: flex;
    flex-direction: row;
    gap: 1.5rem;
}

.member-checkbox-row {
    display: flex;
    gap: 2rem;
}

.member-subtitle {
    display: flex;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #475467;
}

.checkbox-container {
    display: flex;
    width: 15rem;
    flex-direction: row;
    gap: 0.5rem;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #344054;
}

.owner-input {
    display: flex;
    border: 1px solid #D0D5DD;
    border-radius: 8px;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    background: #F9FAFB;
    padding: 10px 14px;
}

.owner-help-input {
    display: flex;
    -webkit-appearance: none;
    border-radius: 0px;
    border: none;
    background: inherit;
    width: 224px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #667085
}

.owner-help-icon {
    display: flex;
}

.transfer-ownership-btn {
    display: flex;
    gap: 8px;
    background-color: #FCFCFD;
    border: none;
    padding: 6px 0px 0px;
    margin: auto;
    cursor: pointer;
    color: #365A6D;
}

.transfer-ownership-btn:disabled {
    display: flex;
    gap: 8px;
    color: #667085;
    background-color: #FCFCFD;
    border: none;
    padding: 6px 0px 0px;
    margin: auto;
    cursor: not-allowed;
}

.transfer-ownership-text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    /* Primary/600 */
    color: inherit;
}

.delete-btn {
    display: flex;
    height: 2.75rem;
    cursor: pointer;
}

.button-end-justify {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-end;
}