.footer-navigation-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0rem 1rem 2rem 1rem;
    gap: 1.5rem;
    flex: none;
    order: 0;
    flex-grow: 0;
    align-self: stretch;
}
.footer-nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.25rem;
    flex: none;
    align-self: stretch;
    flex-grow: 0;
    /* padding: 0rem 1rem 0rem 1rem; */
}
.menu-options{
    display: flex;
    align-items: flex-end;
    left: 17rem;
    bottom: 2rem;
    position: absolute;
    z-index: 3;
}
.menu-options-shadow {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 1rem;
    background: #FFFFFF;
    border: 0.063rem solid #F2F4F7;
    box-shadow: 0rem 0.75rem 1rem -0.25rem rgba(16, 24, 40, 0.08), 0rem 0.25rem 0.375rem -0.125rem rgba(16, 24, 40, 0.03);
    border-radius: 0.75rem;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}