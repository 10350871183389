.configuration-connectivity-container {
    display: flex; 
    flex-direction: column;
    padding: 0px 2rem 0rem;
    gap: 1.5rem; 
    height: 100%;
}

.configuration-connectivity-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1.5rem 0rem 0rem;
    gap: 1.25rem;
}

.configuration-connectivity-tabs {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0rem;
    gap: 0.5rem;
    border-bottom: 0.0625rem solid #EAECF0;
    width: 100%;
}

.configuration-connectivity-body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0rem;
    gap: 1.25rem;
    height: 100%;
}

.configuration-connectivity-empty-state-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    width: 22rem;
}

.configuration-connectivity-empty-state-text {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    gap: 0.5rem;
    overflow-y: auto;
    justify-content: space-between;
    margin-top: 1.25rem;
}

.configuration-connectivity-form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    width: 100%;
}

.configuration-connectivity-body-row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0rem;
    gap: 2rem;
}

.configuration-connectivity-main-text {
    display: flex;
    flex-direction: column;
    gap: 8px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #344054;
    width: 17.5rem;
}

.configuration-connectivity-subtitle {
    display: flex;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #475467;
    width: 17.5rem;
}

.copy-button-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 32rem;
}

.copy-input-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
    height: 2.875rem;
    padding: 0.625rem 0.875rem;
    gap: 0.5rem;
    background: #FFFFFF;
    border-width: 0.0625rem 0rem 0.0625rem 0.0625rem;
    border-style: solid;
    border-color: #D0D5DD;
    border-radius: 0.5rem 0rem 0rem 0.5rem;
    width: 80%;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #475467;
}

.copy-input-button:focus {
    outline: none;
    box-shadow: none;
}

.copy-input-button:disabled {
    background: #F9FAFB;
}

.copy-button-connectivity {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.625rem 0.875rem;
    gap: 0.5rem;
    width: 20%;
    background: #FFFFFF;
    border-width: 0.0625rem;
    border-style: solid;
    border-color: #D0D5DD;
    border-radius: 0rem 0.5rem 0.5rem 0rem;
    cursor: pointer;
}

.copy-connectivity-text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #475467;
}

.connectivity-input-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 32rem;
}

.connectivity-input-data-limit {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.625rem 0.875rem;
    gap: 0.5rem;
    background: #FFFFFF;
    border-width: 0.0625rem 0rem 0.0625rem 0.0625rem;
    border-style: solid;
    border-color: #D0D5DD;
    border-radius: 0.5rem 0rem 0rem 0.5rem;
}

.connectivity-input-data-limit:disabled {
    background: #F9FAFB;
}

.connectivity-input-data-limit-value {
    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
    padding: 0.625rem 0.875rem;
    gap: 0.5rem;
    background: #FFFFFF;
    border-width: 0.0625rem;
    border-style: solid;
    border-color: #D0D5DD;
    width: 100%;
    height: 2.875rem;
    border-radius: 0rem 0.5rem 0.5rem 0rem;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #475467;
}

.connectivity-input-data-limit-value:focus {
    outline: none;
    box-shadow: none;
}

.connectivity-input-data-limit-value:disabled {
    background: #F9FAFB;
}

.connectivity-input-alert-threshold-value {
    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
    padding: 0.625rem 0.875rem;
    gap: 0.5rem;
    background: #FFFFFF;
    border-width: 0.0625rem;
    border-style: solid;
    border-color: #D0D5DD;
    width: 100%;
    height: 2.875rem;
    border-radius: 0rem 0.5rem 0.5rem 0rem;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #475467;
}

.connectivity-input-alert-threshold-value:focus {
    outline: none;
    box-shadow: none;
}

.connectivity-input-alert-threshold-value:disabled {
    background: #F9FAFB;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button  {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance:textfield;
}

.connectivity-input-text{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #475467;
}

.configuration-connectivity-footer {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    /* align-items: flex-end; */
    gap: 1.5rem;
    /* justify-content: flex-end; */
}

.configuration-connectivity-footer-buttons {
    display: flex;
    align-items: flex-end;
    width: 100%;
    height: 100%;
    justify-content: flex-end;
    gap: 0.5rem;
}