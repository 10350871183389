.login-row {
    display: flex;
    flex-direction: row;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}

.login-column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 38.5rem;
}

.login-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    align-items: center;
    padding: 0px 140px;
}

.login-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: auto;
    padding: 0px;
    gap: 80px;
}

.login-logo {
    display: flex;
    flex-direction: column;
    align-items: left;
    gap: 0.25rem;
}

.login-logo-alt {
    width: 53%;
    height: auto;
}

.login-footer {
    display: flex;
    padding: 2rem;
    height: auto;
}

.login-form {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.login-form-header {
    display: flex;
    flex-direction: row;
}

.login-form-content {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.login-form-input {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 20px;
    width: 100%;
}

.input-container {
    display: flex;
    flex-direction: column;
    gap: 0.375rem;
    width: 100%;
}

.login-form-checkbox {
    display: flex;
    justify-content: space-between;
}

.login-checkbox {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 0.25rem;
    flex: none;
    order: 0;
    flex-grow: 1;
}

.login-checkbox-input {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 1rem;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.login-checkbox-label {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0rem;
    flex: none;
    order: 1;
    flex-grow: 1;
}

.login-checkbox-label-style {
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #344054;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.forgot-password-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0rem;
    gap: 0.5rem;
    width: 7.125rem;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}

.login-action {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0rem;
    gap: 1rem;
    flex: none;
    order: 2;
    align-self: stretch;
    flex-grow: 0;
}

.login-form-starter-kit {
    display: flex;
    justify-content: center;
    gap: 4px;
}

.login-row-text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #475467;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.login-image {
    display: flex;
    width: 100%;
    /* background: #F2F4F7; */
    background-image: url('../login//img/loginimage.svg');
    background-position: center;
    background-size: cover;
}

.login-wrapper {
    display: flex;
    flex-direction: column;
    gap: 8rem;
}

.login-text-container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 3.5rem 4rem 0rem 4rem;
}

.login-text {
    padding-bottom: 1rem;
    font-style: normal;
    font-weight: 500;
    font-size: 1.875rem;
    line-height: 2.375rem;
    color: #101828;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 1;
    cursor: default;
}

.login-text-background {
    padding: 1.25rem;
    background: rgba(255, 255, 255, 0.6);
    -webkit-backdrop-filter: blur(0.188rem);
    backdrop-filter: blur(0.188rem);
    border-radius: 0.5rem;
    flex: none;
    order: 0;
    flex-grow: 1;
}

.powered-text {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 5.875rem;
    height: 1.5rem;
}

.email-password-error {
    width: 22.5rem;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    color: #F04438;
    flex: none;
    flex-grow: 0;
    cursor: default;
}

.notification {
    display: flex;
    align-items: flex-end;
    right: 1.5rem;
    top: 1.5rem;
    position: absolute;
}

.login-mainMobile {
    overflow: hidden;
}

.login-row-mobile {
    display: flex;
    max-width: 1224px;
    height: 100%;
    box-sizing: border-box;
    padding: 3rem 1rem 3rem 1rem;
}

.login-container-mobile {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
}

.login-header-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    gap: 2rem;
}

.login-header-mobile {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
}

.logo-mobile-alt {
    width: 7.25rem;
    height: auto;
}

.login-header-content-mobile {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-top: 1rem;
}

.login-content-mobile {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1.5rem;
}

.login-form-mobile {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 1.25rem;
}

.login-checkbox-row-mobile {
    display: flex;
    flex-direction: row;
}

.login-actions-mobile {
    display: flex;
}

.login-footer-container {
    display: flex;
    margin: auto;
}

.login-footer-mobile {
    display: flex;
}