.create-agency-container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.create-agency-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1.5rem 2rem 0rem;
    gap: 1.5rem;
}

.create-agency-form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 0rem 2rem;
}

.create-agency-form-header {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
}

.create-agency-form-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.create-agency-button-group {
    display: flex;
    flex-direction: row;
    gap: 0.75rem;
}

.create-agency-form-body {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
}

.create-agency-form-row {
    display: flex;
    flex-direction: row;
    gap: 2rem;
}

.create-agency-row-content {
    display: flex;
    flex-direction: column;
    max-width: 17.5rem;
    min-width: 17.5rem;
}

.create-agency-row-text {
    display: flex;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #344054;
}

.create-agency-required-field {
    display: flex;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #344054;
}

.create-agency-required-field::after {
    content: "*";
    color: red;
}

.create-agency-row-supporting-text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #475467;
}

.create-agency-input {
    display: flex;
    flex-direction: column;
    gap: 0.375rem;
}

.create-agency-error {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #F04438;
}

.create-agency-footer {
    display: flex;
    flex-direction: row;
    gap: 0.375rem;
    justify-content: flex-end;
}