.text-input-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.375rem;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    cursor: default;
    width: 100%;
}
.text-input-label {
    font-style: normal;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #344054;
    flex: none;
    order: 0;
    flex-grow: 0;
}
.text-input {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    position: relative;
}
.iconnn-invalid{
    position: absolute;
    right: 1rem;
}
.iconnn-valid {
    position: absolute;
    left: 1rem;
}
.iconnn-forgot{
    position: absolute;
    left: 1rem;
}
.iconnn-google{
    position: absolute;
    left: 1rem;
    width: 20px;
}
.iconnn-show{
    position: absolute;
    right: 1rem;
    cursor: pointer;
    height: 1rem;
}
.text-input-box {
    font-family: 'Inter';
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.625rem 0.875rem;
    gap: 0.5rem;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #101828;
    flex: none;
    order: 0;
    flex-grow: 0;
    border-radius: 0.5rem;
    box-shadow: 0rem 0.063rem 0.125rem rgba(16, 24, 40, 0.05);
    align-self: stretch;
    width: 20.625rem;
}

.text-input-box:disabled {
    background-color: #F9FAFB;
}

.text-input-box:disabled::placeholder {
    color: #667085;
}

.text-input-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
    width: 20.75rem;
    height: 1.5rem;
    flex: none;
    order: 0;
    flex-grow: 1;
}
.text-input-text {
    width: 20.75rem;
    height: 1.5rem;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #667085;
    flex: none;
    order: 0;
    flex-grow: 1;
}
.text-input::placeholder {
    font-size: 1rem;
}