.geofence-alert-details-container {
    display: flex;
    flex-direction: column;
}

.geofence-alert-details-back-button {
    display: flex;
    gap: 0.5rem;
    margin: 0rem 0rem 2rem 0rem;
}

.geofence-alert-detail-text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.25rem;
}

.geofence-alert-details-container-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0rem 0rem 1.25rem 0rem;
    border-bottom: 1px solid #EAECF0;
}

.geofence-alert-details-button {
    display: flex;
    gap: 0.75rem;
}

.geofence-alert-details-policy-container {
    display: grid;
    grid-auto-columns: 0fr;
    grid-template-columns: 17.5rem 1fr;
    grid-template-rows: 1fr;
    gap: 0rem 2rem;
    grid-template-areas:
        ". .";
    padding: 1.5rem 0rem 1.25rem 0rem;
    border-top: 1px solid #EAECF0;
    border-bottom: 1px solid #EAECF0;
}

.geofence-alert-details-description-container {
    display: grid;
    grid-auto-columns: 0fr;
    grid-template-columns: 17.5rem 1fr;
    grid-template-rows: 1fr;
    gap: 0rem 2rem;
    grid-template-areas:
        ". .";
    padding: 1.5rem 0rem 1.25rem 0rem;
    border-bottom: 1px solid #EAECF0;
}


.geofence-alert-details-areas-container {
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-columns: 17.5rem 1fr;
    grid-template-rows: 1fr;
    gap: 0rem 2rem;
    grid-template-areas:
        ". .";
    padding: 1.25rem 0rem 1.25rem 0rem;
    border-bottom: 1px solid #EAECF0;
}

.geofence-alert-details-areas-map {
    /* width: '100%'; */
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 0rem 2rem;
    grid-template-areas:
        ". .";
    height: 25.375rem;

}

.geofence-alert-details-area-name-list {
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-columns: 1fr;
    grid-template-rows: minmax(0, 42px) minmax(0, 1fr) minmax(0, 52px);
    gap: 0rem 0rem;
    grid-template-areas:
        "."
        "."
        ".";
    border: 0.063rem solid #EAECF0;
    border-radius: 0.75rem;
    box-shadow: 0rem 0.063rem 0.188rem rgba(16, 24, 40, 0.1), 0rem 0.063rem 0.125rem rgba(16, 24, 40, 0.06);
}

.geofence-alert-details-header-area-name-list {
    padding: 0.75rem 1.5rem 0.75rem 1.5rem;
    background: #F9FAFB;
    color: #475467;
    border-bottom: 1px solid #EAECF0;
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem;
    font-style: normal;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 1.125rem;
}

.geofence-alert-details-footer-area-name-list {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    padding: 0.75rem 1.5rem 0.75rem 1.5rem;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #365A6D;
    border-top: 1px solid #EAECF0;
    ;
}

.geofence-alert-details-footer-area-name-list-disabled {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    padding: 0.75rem 1.5rem 0.75rem 1.5rem;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #365A6D;
    border-top: 1px solid #EAECF0;
    ;
    pointer-events: none;
    opacity: 0.5;
}

.geofence-alert-details-body-a-n-border-container {}

.geofence-alert-details-body-a-n-no-border-container {}

.geofence-alert-details-body-area-name-list {
    display: flex;
    justify-content: space-between;
    /* align-self: flex-start; */
    padding: 1rem 1.5rem 1rem 0rem;
    /* border-bottom: 1px solid #EAECF0; */
}

.geofence-alert-details-body-area-name-list-with-divider {
    display: flex;
    justify-content: space-between;
    /* align-self: flex-start; */
    padding: 1rem 1.5rem 1rem 0rem;
    border-bottom: 1px solid #EAECF0;
}

.geofence-alert-details-body-area-name-list-active {
    display: flex;
    justify-content: space-between;
    /* align-self: flex-start; */
    padding: 0.563rem 1.5rem 0.563rem 0rem;
    border-bottom: 1px solid #EAECF0;
    background: #F9FAFB;
}

.g-detail-a-btn {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.25rem;
}

.g-detail-a-del-btn {
    color: #B42318;
}

.g-detail-a-edit-btn {
    color: #26404E;
}

.g-detail-a-btn-disabled {
    pointer-events: none;
    opacity: 0.5;
}

.g-detail-a-input-text {
    padding: 8px 12px 8px 12px;
    margin: 0rem 1.5rem 0rem 1.5rem;
    width: 100%;
    background: #FFFFFF;
    /* Gray/300 */

    border: 1px solid #D0D5DD;
    /* Shadow/xs */

    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
}

.g-detail-a-input-text:focus {
    outline-width: 0;
}

.g-detail-a-label-text {
    display: flex;
    align-items: center;
    padding: 0rem 1.5rem 0rem 1.5rem;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 1.125rem;
    color: #101828;
}

.g-detail-action-checkbox-container {
    display: flex;
    gap: 0.5rem;
}

.g-detail-action-checkbox-text-header {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #344054;
}

.g-detail-button-container {
    display: flex;
    justify-content: flex-end;
    padding: 1.25rem 0rem 0rem 0rem;
}

.geofence-alert-details-body-a-n-container {
    max-height: 19.938rem;
    overflow-y: auto;
}

.geofence-snackbar-container {
    position: absolute;
    top: 24px;
    right: 24px;
}