.security-container {
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-columns: 1fr;
    grid-template-rows: minmax(5.875rem, 7.875rem) minmax(0px, 1.73fr);
    gap: 2rem 0px;
    width: 100%;
    height: 100%;
    background: #FCFCFD;
}

.security-header-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 5.875rem;
    gap: 1.5rem;
    padding: 2rem 2rem 0rem;
}

.security-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.security-tabs {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0rem;
    gap: 0.5rem;
    border-bottom: 0.0625rem solid #EAECF0;
    width: 100%;
}

.security-column {
    display: flex;
    flex-direction: row;
    height: 100%;
    gap: 5rem;
    padding: 0rem 5rem;
    justify-content: space-between;
}

.security-password-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    height: 100%;
    width: 40rem;
}

.security-password-form {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 1.25rem;
}

.security-password-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.security-password-strength-container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 40rem;
}

.securityWrapperBar {
    flex: none;
    flex-grow: 0;
}

:root {
    --strength-bar-width: 0vw;
}

.securityContainerBar {
    background-color: #EAECF0;
    height: 0.5rem;
    border-radius: 0.313rem;
}

.securityBarCompletedBar {
    background-color: #365A6D;
    width: var(--strength-bar-width);
    height: 0.5rem;
    border-radius: 0.313rem;
    transition: width 1s ease-in-out 0s;
}

.security-authentication {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1.5rem;
}

.two-factor-container {
    display: flex;
    flex-direction: row;
}

.security-two-factor-container {
    display: flex;
    width: 25.8125rem;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #475467;
}

.security-button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 4px;
}

.security-coming-soon-badge {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 2px 8px;
    border: 1.5px solid #475467;
    border-radius: 16px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #344054;
}

.single-sign-on-container {
    display: flex;
    flex-direction: row;
}

.security-logged-in-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 30.25rem;
    gap: 1.25rem;
}

.security-logged-in-header {
    display: flex;
    flex-direction: row;
}

.security-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding: 0.5rem;
    gap: 0.5rem;
    position: relative;
    right: 0rem;
    top: 0rem;
    border-radius: 0.5rem;
    flex: none;
    order: 1;
    flex-grow: 0;
    z-index: 0;
    cursor: pointer;
    height: fit-content;
}

.security-menu {
    /* width: 1.25rem; */
    height: 1.25rem;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.security-password-error {
    width: 22.5rem;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    color: #F04438;
    flex: none;
    flex-grow: 0;
    cursor: default;
}

.security-password-input {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 0.375rem;
}