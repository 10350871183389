.step-list-styling {
    display: flex;
    flex-direction: column;
    margin: 0px;
    padding-inline-start: 15px;
}

.Custom-step-content-completed {
    display: flex;
    border-left: 2px solid #365A6D;
    margin-left: 12px;
}

.Custom-step-content-last {
    display: flex;
    margin-left: 12px;
}

.Custom-step-content {
    display: flex;
    border-left: 2px solid #EAECF0;
    margin-left: 12px;
}