.text-title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    line-height: 2.375rem;
    color: #101828;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}
.supporting-text-title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    color: #475467;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}
.title-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.25rem;
    flex: none;
    order: 0;
    flex-grow: 1;
    cursor: default;
}
