.menu-container {
    display: flex;
    align-items: flex-start;
    gap: 8px;
}

.menu-icon {
    display: flex;
    flex-direction: row;
    margin: auto;
}

.menu-left {
    display: flex;
    flex-grow: 1;
}

.menu-text {
    display: flex;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #101828;
    margin-top: auto;
    margin-bottom: auto;
}

.menu-subtitle {
    display: flex;
    flex-grow: 1;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #475467;
    margin-top: auto;
    margin-bottom: auto;
}