.drawer-container {
    height: 35rem;
    display: flex;
    width: 100%;
    position: absolute;
    bottom: 0;
    background-color: white;
    transition: 0.2s linear height;
    flex-direction: column;
    z-index: 2;
    overflow: hidden;
    box-shadow: 0rem -0.2rem 0.5rem rgba(16, 24, 40, 0.1);
}

.drawer-header {
    display: flex;
    height: 7rem;
    width: 100%;
    background-color: #F9FAFB;
    flex-direction: row;
    box-sizing: border-box;
    padding: 20px 24px;
    justify-content: space-between;
}

.drawer-header-mainText {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    gap: 8px;
    font: Inter;
    color: #667085;
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
}

.drawer-header-textValue {
    display: flex;
    font: Inter;
    color: #26404E;
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
}

.drawer-header-buttonGroup {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    align-self: center;
}

.drawer-body {
    display: flex;
    width: 100%;
    height: 15rem;
}