.otp-group {
    display: flex;
    width: 100%;
    column-gap: 10px;
    align-items: center;
    color: #d0d5dd;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 60px;
    line-height: 72px;
    letter-spacing: -0.02em;
}

.otp-input {
    width: 100%;
    height: 80px;
    width: 80px;
    border: 2px solid #365a6d;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 60px;
    text-align: center;
    color: #365a6d;
}

.otp-input:focus {
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #F4EBFF;
    outline-width: 0;
}