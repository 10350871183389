.date-tab-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0rem;
    isolation: isolate;
    filter: drop-shadow(0rem 0.063rem 0.125rem rgba(16, 24, 40, 0.05));
    border-radius: 0.5rem;
}
.date-tab-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.625rem 1rem;
    gap: 0.5rem;
    height: 2.5rem;
    /* Gray/50 */
    border: 0.063rem solid #D0D5DD;
    background: #FFFFFF;
    /* Gray/300 */
    cursor: pointer;
    /* border-right: 0.063rem solid #D0D5DD; */
}
.date-tab-text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #344054;
}