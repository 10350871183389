.notification-history-container {
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    gap: 1.5rem;
}

.notification-history-header {
    padding: 0px 2rem;
    display: flex;
    gap: 24px;
    box-sizing: border-box;
    flex-direction: column;
}

.notification-history-body {
    display: flex;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}