.progress-bar {
    display: flex;
    justify-content: center;
    align-items: center;
}

.progress-bar-row {
    display: grid;
    grid-template-columns: 11.375rem 1fr;
    grid-template-rows: 1fr;
    gap: 0.75rem;
}

.progress-bar-value {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.25rem;
}

progress {
    -webkit-appearance: none;
    width: 100%;
    height: 0.5rem;
}

::-webkit-progress-bar {
    height: 0.5rem;
    border-radius: 1.25rem;
    background-color: #eee;
}

::-webkit-progress-value {
    height: 0.5rem;
    border-radius: 1.25rem;
    background-color: #365A6D;
}