.snackbar-shadow {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 1rem;
    /* width: 23.75rem; */
    /* height: 6.875rem; */
    background: #FFFFFF;
    border: 0.063rem solid #F2F4F7;
    box-shadow: 0rem 0.75rem 1rem -0.25rem rgba(16, 24, 40, 0.08), 0rem 0.25rem 0.375rem -0.125rem rgba(16, 24, 40, 0.03);
    border-radius: 0.75rem;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}
.snackbar-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0.8rem 1rem 0.8rem 1rem;
    gap: 1rem;
    width: 23.75rem;
    height: 100%;
    background: #FFFFFF;
    border: 0.063rem solid #F2F4F7;
    box-shadow: 0rem 0.75rem 1rem -0.25rem rgba(16, 24, 40, 0.08), 0rem 0.25rem 0.375rem -0.125rem rgba(16, 24, 40, 0.03);
    border-radius: 0.75rem;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}
.snackbar-contents {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0rem;
    /* gap: 1.25rem; */
    gap: 2rem;
    width: 20.75rem;
    height: 100%;
    flex: none;
    order: 0;
    flex-grow: 1;
}
.snackbar-icon-container{
    width: 2rem;
    height: 2rem;
    flex: none;
    order: 0;
    flex-grow: 0;
    justify-content: center;
}
.snackbar-icon-circle {
    display: flex;
    width: 2.5rem;
    height: 2.5rem;
    /* left: -0.313rem;
    top: -0.25rem; */
    /* background: #FEE4E2;
    border: 0.375rem solid #FEF3F2; */
    border-radius: 1.75rem;
    justify-content: center;
    align-self: center;
}
.snackbar-icon-key 
{
    display: flex;
    align-self: center;
    width: 1.25rem;
    height: 1.25rem;
    left: 0.625rem;
    top: 0.625rem;
}
.snackbar-text-content{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0.125rem 0rem 0rem;
    gap: 0.625rem;
    width: 17.5rem;
    height: auto;
    flex: none;
    order: 1;
    flex-grow: 1;
}
.snackbar-close-container{
    width: 1.25rem;
    height: 1.25rem;
    flex: none;
    order: 1;
    flex-grow: 0;
    cursor: pointer;
    padding-top: 0.25rem;
}
.snackbar-close-button{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.snackbar-close-button-icon-container{
    width: 1.25rem;
    height: 1.25rem;
    flex: none;
    order: 0;
    flex-grow: 0;
}
.snackbar-close-button-icon{
    left: 25%;
    right: 25%;
    top: 25%;
    bottom: 25%;
}