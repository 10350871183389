.map-overview-container {
    display: grid; 
    grid-auto-columns: 1fr; 
    grid-template-rows: minmax(0rem, 4rem) minmax(0rem, 4.125rem) minmax(0rem, 2.625rem) 1fr; 
    gap: 1.5rem 0rem;
    height: 100%;
}
.map-overview-title{
    padding: 0rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
}
.map-overview-tabs {
    padding: 0rem 2rem;
    display: flex;
    justify-content: space-between;
}
.map-overview-buttons-input {
    display: flex;
    gap: 0.75rem;
}
.map-overview-map {
    padding: 0rem 2rem 2rem 2rem;
    display: flex;
}

.live-Drawer {
    border: 0.063rem solid #EAECF0;
    box-shadow: 0rem 0.063rem 0.188rem rgba(16, 24, 40, 0.1), 0rem 0.063rem 0.125rem rgba(16, 24, 40, 0.06);
}