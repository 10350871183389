.g-details-action-user-container {
    display: flex;
    flex-direction: column;
    box-shadow: 0rem 0.063rem 0.188rem rgba(16, 24, 40, 0.1), 0rem 0.063rem 0.125rem rgba(16, 24, 40, 0.06);
    border: 0.063rem solid #EAECF0;
    border-radius: 0.75rem;
}

.g-details-action-user-header {
    display: grid; 
    grid-auto-columns: 1fr; 
    grid-template-columns: 1fr 1fr 0.5fr; 
    grid-template-rows: 1fr; 
    gap: 0rem 0rem; 
    grid-template-areas: 
      ". . ."; 
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 1.125rem;
    color: #475467;
    padding: 0.75rem 1.5rem;
    background: #F9FAFB;
    border-bottom: 0.063rem solid #EAECF0;
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem;
}
.g-details-action-user-button {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #365A6D;
    padding: 1rem 1.5rem;
    border-top: 0.063rem solid #EAECF0;
}

.g-details-action-user-body {
    display: grid; 
    grid-auto-columns: 1fr; 
    grid-template-columns: 1fr 1fr 0.5fr; 
    grid-template-rows: 1fr; 
    gap: 0rem 0rem; 
    grid-template-areas: 
      ". . ."; 
    padding: 1rem 1.5rem;
}

.g-details-action-user-body-with-above-divider {
    display: grid; 
    grid-auto-columns: 1fr; 
    grid-template-columns: 1fr 1fr 0.5fr; 
    grid-template-rows: 1fr; 
    gap: 0rem 0rem; 
    grid-template-areas: 
      ". . ."; 
    padding: 1rem 1.5rem;
    border-top: 0.063rem solid #EAECF0;
}

.g-details-action-user-body-with-divider {
    display: grid; 
    grid-auto-columns: 1fr; 
    grid-template-columns: 1fr 1fr 0.5fr; 
    grid-template-rows: 1fr; 
    gap: 0rem 0rem; 
    grid-template-areas: 
      ". . ."; 
    padding: 1rem 1.5rem;
    border-bottom: 0.063rem solid #EAECF0;
}

.g-details-action-user-body-divider-temp {
    display: grid; 
    grid-auto-columns: 1fr; 
    grid-template-columns: 1fr 1fr 0.5fr; 
    grid-template-rows: 1fr; 
    gap: 0rem 0rem; 
    grid-template-areas: 
      ". . ."; 
    padding: 1rem 1.5rem;
    border-bottom: 0.063rem solid #EAECF0;
}

.g-details-action-user-remove-button {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #B42318;
    gap: 0.75rem;
}

.g-details-action-user-avatar-body {
    display: flex;
    align-items: center;
    gap: 0.75rem;
}

.g-details-action-user-body-name {
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 1.125rem;
    color: #101828;
}

.g-details-action-user-body-email {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #475467;
}

.g-details-action-user-body-notification{
    display: flex;
    align-items: center;
}
.g-details-action-remaining-elipsis {
    background: #F2F4F7;
    border-radius: 1rem;
    padding: 0.125rem 0.5rem;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 1.125rem;
    margin-left: 4px;
}

.g-details-action-user-body-elipsis-container {
    display: flex;
}

.g-details-action-user-body-elipsis-container .gap {
    display: flex;
    gap: 0.25rem;
}

.g-details-action-user-body-notification .empty {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 1.125rem;
    color: #B54708;
    /* Warning/600 */
    border: 0.094rem solid #DC6803;
    border-radius: 1rem;
    padding: 0.125rem 0.5rem 0.125rem 0.375rem;
}

.g-details-action-user-body-list-container {
    max-height: 27.313rem;
    overflow-y: auto;
}