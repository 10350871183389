
.inventory-container {
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-columns: 1fr;
    grid-template-rows: minmax(0px, 4rem) minmax(0px, 7.125rem) minmax(0px, 1fr);
    gap: 1.5rem 0px;
    height: 100%;
    width: 100%;
}

.inventory-header {
    padding: 0rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
}

.inventory-body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: #FFFFFF;
    margin: 0rem 2rem 3rem;
    border: 0.0625rem solid #EAECF0;
    /* Shadow/sm */
    box-shadow: 0rem 0.0625rem 0.1875rem rgba(16, 24, 40, 0.1), 0rem 0.0625rem 0.125rem rgba(16, 24, 40, 0.06);
    border-radius: 0.75rem;
}

.inventory-body-filter {
    display: flex;
    padding: 0.75rem 1rem;
    width: calc(100% - 2rem);
    justify-content: space-between;
    border-bottom: 0.0625rem solid #EAECF0;
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem;
    /* box-shadow: 0rem 0.0625rem 0.1875rem rgba(16, 24, 40, 0.1), 0rem 0.0625rem 0.125rem rgba(16, 24, 40, 0.06); */

    /* border-bottom: 0.0625rem solid #EAECF0; */
}

.bulkAction-button-container {
    display: flex;
}

.inventory-filter-button {
    display: flex;
    gap: 0.75rem;
}

.inventory-body-empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.25rem;
    height: 100%;
    width: 100%;
}

.inventory-body-empty-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: auto;
    gap: 1.25rem;
}
.inventory-body-empty-text {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    gap: 0.5rem;
    overflow-y: auto;
}

.inventory-body-text {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    gap: 0.5rem;
    overflow-y: auto;
    /* border: 0.0625rem solid #EAECF0; */
    /* border-bottom-left-radius: 0.75rem; */
    /* border-bottom-right-radius: 0.75rem; */
    /* box-shadow: 0rem 0.0625rem 0.1875rem rgba(16, 24, 40, 0.1), 0rem 0.0625rem 0.125rem rgba(16, 24, 40, 0.06); */
    justify-content: space-between;
}

.inventory-body-text-loading {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    gap: 0.5rem;
    overflow-y: auto;
    /* border: 0.0625rem solid #EAECF0; */
    /* border-bottom-left-radius: 0.75rem; */
    /* border-bottom-right-radius: 0.75rem; */
    /* box-shadow: 0rem 0.0625rem 0.1875rem rgba(16, 24, 40, 0.1), 0rem 0.0625rem 0.125rem rgba(16, 24, 40, 0.06); */
    justify-content: space-around;
}