.topbar-container-main {
    display: flex;
    position: fixed;
    width: 100%;
    box-sizing: border-box;
    padding: 14px 16px;
    justify-content: space-between;
    background: #fff;
    z-index: 99;
    border-bottom: 1px solid #EAECF0;
}

.topbar-menu-container {
    display: flex;
}

.topbar-menu-blur {
    position: fixed;
    box-sizing: border-box;
    padding: 0;
    top: 64px;
    width: 100%;
    height: 100%;
    background: rgba(52, 64, 84, 0.7);
    backdrop-filter: blur(8px);
    z-index: 3;
}

.topbar-menu {
    position: fixed;
    box-sizing: border-box;
    padding: 0;
    background: #fff;
    width: 100%;
    border-bottom: 1px solid #EAECF0;
}

.topbar-logo-container {
    display: flex;
    flex-direction: row;
    gap: 0.25rem;
}

.logo-image {
    width: 6.375rem;
    height: 2.188rem;
}

.logo-image:last-child {
    margin-top: 0.3rem;
    width: 6.3rem;
    height: 2.188rem;
    object-fit: contain;
}
