.tripList-container {
    display: flex;
    position: absolute;
    flex-direction: column;
    width: 24.25rem;
    height: auto;
    background-color: #FFFFFF;
    border: 0.063rem solid #EAECF0;
    box-sizing: border-box;
    right: 0.5rem;
    margin-top: 0.5rem;
    box-shadow: 0rem 0.063rem 0.188rem rgba(16, 24, 40, 0.1), 0rem 0.063rem 0.125rem rgba(16, 24, 40, 0.06);
    border-radius: 0.75rem;
}

.tripList-header {
    display: flex;
    background-color: #F9FAFB;
    padding: 16px 24px;
    border-radius: 0.75rem 0.75rem 0rem 0rem;
    box-shadow: 0rem 0.063rem 0.188rem rgba(16, 24, 40, 0.1), 0rem 0.063rem 0.125rem rgba(16, 24, 40, 0.06);
    box-sizing: border-box;
    font-style: Inter;
    color: #344054;
    font-weight: 800;
    font-size: 16px;
    line-height: 18px;
}

.tripList-body {
    height: 24.75rem;
    width: 24.25rem;
    display: flex;
    overflow: auto;
    will-change: transform;
    position: relative;
    flex-direction: column;

}
.tripList-row {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    gap: 1rem;
    padding: 16px 24px;
    /* justify-content: space-between; */
    box-sizing: border-box;
}

.tripList-origin-destination {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
}

.tripList-origin-container {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
}

.tripList-origin-road-container {
    font-style: Inter;
    color: #344054;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
}

.tripList-origin-timestamp-container {
    display: flex;
    font-style: Inter;
    color: #667085;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
}

.tripList-destination-container {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
}

.tripList-destination-road-container {
    font-style: Inter;
    color: #344054;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
}

.tripList-destination-timestamp-container {
    display: flex;
    font-style: Inter;
    color: #667085;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
}

.tripList-tripDetails-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.tripList-tripDetails-row {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    gap: 1rem;
    padding: 16px 24px;
    box-sizing: border-box;
}

.tripList-tripDetails-distance-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 0.5rem;
}

.tripList-tripDetails-distance-text-container {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
}

.tripList-tripDetails-distance-text {
    font-weight: 500;
    display: flex;
    align-items: center;
    font-size: 0.75rem;
    line-height: 1.125rem;
    color: #344054;
    gap: 0.25rem;
}

.tripList-tripDetails-text {
    display: flex;
    font-style: Inter;
    color: #344054;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
}

.tripList-icons {
    display: flex;
    height: 100%;
    flex-direction: column;
    gap: 2px;
    align-items: center;
}

.transition-container {
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;
    /* height: 28.25rem; */
    will-change: transform, opacity;
    -webkit-user-select: none;
    user-select: none;
    box-shadow: 0rem -0.2rem 0.5rem rgba(16, 24, 40, 0.1);
    border-radius: 0rem 0rem 0.75rem 0.75rem;
}

.tripDetails-container {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    gap: 0.75rem;
    box-sizing: border-box;
}

.tripDetails-container-header {
    display: flex;
    padding: 1rem 1rem 0rem;
    box-sizing: border-box;
}

.tripDetails-title-container {
    display: flex;
    gap: 1.5rem;
    flex-direction: row;
    align-items: center;
}

.tripDetails-container-body {
    display: flex;
    width: 100%;
    flex-direction: column;
}

.tripDetails-device-header {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 0.25rem;
    padding: 1rem 1.5rem;
    box-sizing: border-box;
    /* border-bottom: solid 0.063rem #EAECF0; */
}

.tripDetails-deviceName-container {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
}

.tripDetails-deviceName {
    display: flex;
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.75rem;
    color: #365A6D;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 12.5rem;
}

.tripDetails-deviceSN-container {
    display: flex;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.125rem;
    color: #475467;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 12.5rem;
}

.tripDetails-deviceSN {
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.75rem;
    color: #365A6D;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 12.5rem;
}

.tripDetails-separator {
    display: flex;
    width: 100%;
    border-bottom: solid 0.063rem #EAECF0;
}

.tripDetails-origin-destination-container {
    display: flex;
    width: 100%;
    padding: 0rem 1rem;
}

.tripDetails-distance-duration-container {
    display: flex;
    width: 100%;
    padding: 1rem 1.5rem;
    flex-direction: row;
    box-sizing: border-box;
    gap: 1rem;
}

.tripDetails-CTA-button-container {
    display: flex;
    align-items: center;
    justify-content: center;
}