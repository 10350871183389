.trip-report-container {
    display: flex;
    padding: 1.5rem 0rem;
    box-sizing: border-box;
    flex-direction: column;
    width: 100%;
    height: 100%;
    gap: 1.5rem;
}

.trip-report-header {
    display: flex;
    padding: 0rem 2rem;
    box-sizing: border-box;
    gap: 1.5rem;
    width: 100%;
    flex-direction: column;
}

.trip-report-header-title-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.trip-report-body {
    display: flex;
    width: 100%;
    height: 100%;
    padding: 0rem 2rem;
    box-sizing: border-box;
}

.trip-report-map-container {
    display: flex;
    width: 100%;
    height: 100%;
    position: relative;
    box-sizing: border-box;
    flex-direction: column;
    border: 0.063rem solid #EAECF0;
    border-radius: 0.75rem;
}

.trips-notification-body {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.trips-progress-bar {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.trips-progress-bar-text {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.trip-marker-button-container {
    display: flex;
    position: absolute;
    flex-direction: row;
    width: auto;
    height: auto;
    background-color: #FFFFFF;
    border: 0.063rem solid #EAECF0;
    box-sizing: border-box;
    left: 0.5rem;
    margin-top: 0.5rem;
    box-shadow: 0rem 0.063rem 0.188rem rgba(16, 24, 40, 0.1), 0rem 0.063rem 0.125rem rgba(16, 24, 40, 0.06);
    border-radius: 0.75rem;
    z-index: 1;
    gap: 8px;
    padding: 12px;
    align-items: center;
}

.trip-marker-button-text {
    display: flex;
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #344054;
}