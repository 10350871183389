.asset-type-grid {
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 0px 0.75rem;
}
.asset-type-text {
    margin: auto;
}

.tags {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 2px 8px; 
    background: #D0E0E8;
    mix-blend-mode: multiply;
    border-radius: 16px;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
  
.tags-text {
    display: flex;
    margin: auto;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #26404E;
}

.overflow-tags {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 2px 8px; 
    background: #F2F4F7;
    mix-blend-mode: multiply;
    border-radius: 16px;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.assets-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
    gap: 0.5rem;
    position: relative;
    right: 0rem;
    top: 0rem;
    border-radius: 0.5rem;
    flex: none;
    order: 1;
    flex-grow: 0;
    z-index: 0;
    cursor: pointer;
}

.assets-menu {
    /* width: 1.25rem; */
    height: 1.25rem;
    flex: none;
    order: 0;
    flex-grow: 0;
}
.table-menu-options{
    display: flex;
    align-items: flex-end;
    left: 17rem;
    bottom: 2rem;
    position: absolute;
    z-index: 3;
    height: 9.375rem;
}

.table-date-text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #101828;
}

.table-time-text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #475467;
}

.data-usage-text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #475467;
}

.asset-device-column{
    display: flex;
    width: 100%;
    flex-direction: column;
}

.main-first{
    font-family: 'Inter';
    font-style: normal;
    line-height: 20px;
    font-weight: 500;
    font-size: 14px;
    color: #101828;
}

.secondary-second {
    font-family: 'Inter';
    font-style: normal;
    line-height: 20px;
    font-weight: 400;
    font-size: 14px;
    color: #475467;
}

.elipsis-container {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.geofence-table-policy-name {
    font-weight: 500;
    font-size: 0.875rem;
}

.geofence-table-areas {
    background-color: #F2F4F7;
    border-radius: 1rem;
    padding: 0.125rem 0.5rem;
    display: flex;
    
}

.geofence-table-areas-text {
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 1.125rem;
    text-align: center;
    color: #344054;
}