.styleCard {
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    border-radius: 12px;
    border: 1px solid var(--gray-200, #EAECF0);
    background: var(--base-white, #FFF);
    flex: auto;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);
}

.styleCardContent {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    height: 100%;
}

.styleCardTitle {
    display: flex;
    color: var(--gray-900, #101828);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    width: 100%;
}

.styleDescription {
    display: flex;
    align-items: flex-end;
    height: 100%;
}

.card-responsive-chart {
    display: flex;
    width: 100%;
}

.styleCardBody {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
}

.styleBody-text-only {
    display: flex;
    color: var(--gray-900, #101828);
    font-family: Inter;
    font-size: 72px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: -0.72px;
    width: 100%;
    line-height: 44px;
}

.styleCardBody-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.styleSubtitle-text-only {
    display: flex;
    color: var(--gray-600, #475467);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    height: 20px;
}

.styleBody {
    display: flex;
    color: var(--gray-900, #101828);
    font-family: Inter;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 44px;
    letter-spacing: -0.72px;
}

.styleSubtitle {
    display: flex;
    color: var(--gray-600, #475467);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}

.cardText {
    display: flex;
    align-items: center;
    gap: 8px;
    width: max-content;
}

.card-arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2px;
}

.card-arrow-text {
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}