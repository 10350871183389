.battery-low-container {
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 2px 6px 2px 6px;
    background-color: #FFFAEB;
    border-radius: 16px;
}
.battery-low-text {
    color: #B54708;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
}