.dashboard-container {
    padding: 0rem 2rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem 0rem;
}

.dashboard-header {
    padding-top: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    width: 100%;
}

.dashboard-title {
    display: flex;
    flex-direction: row;
}

.dashboard-filters {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.dashboard-date-filter {
    display: flex;
}

.dashboard-datePicker-filter-button {
    display: flex;
    column-gap: 0.75rem;
}

.dashboard-metric-group-container {
    display: flex;
    align-items: center;
}

.dashboard-metric-group {
    display: flex;
    align-items: stretch;
    gap: 24px;
    flex: 1 0 0;
}

.dashboard-assets-overview {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0rem 1.5rem;
    height: 29.5625rem;
    background: #FFFFFF;
    border: 0.0625rem solid #EAECF0;
    box-shadow: 0rem 0.0625rem 0.1875rem rgba(16, 24, 40, 0.1), 0rem 0.0625rem 0.125rem rgba(16, 24, 40, 0.06);
    border-radius: 0.75rem;
    gap: 1.5rem;
}

.dashboard-assets-overview-title-container {
    display: flex;
    flex-direction: column;
    padding-top: 1.5rem;
    width: 100%;
}

.assets-overview-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.dashboard-assets-body {
    height: 100%;
    width: 100%;
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-columns: 1.4fr 0.6fr;
    grid-template-rows: 1fr;
    gap: 3.5rem;
}

.dashboard-map {
    padding: 0rem 0rem 2rem;
    display: flex;
}

.dashboard-asset-status {
    display: flex;
    flex-direction: column;
}

.dashboard-heatmap-utilisation {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0rem 1.5rem;
    height: 22.3125rem;
    background: #FFFFFF;
    border: 0.0625rem solid #EAECF0;
    box-shadow: 0rem 0.0625rem 0.1875rem rgba(16, 24, 40, 0.1), 0rem 0.0625rem 0.125rem rgba(16, 24, 40, 0.06);
    border-radius: 0.75rem;
    gap: 0.75rem;
}

.dashboard-heatmap-utilisation-title {
    display: flex;
    flex-direction: column;
    padding-top: 1.5rem;
    width: 100%;
}

.dashboard-heatmap-utilisation-chart {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}