.notificationList-body {
    display: flex;
    box-sizing: border-box;
    padding: 0rem 2rem;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
}

.notification-feed {
    display: flex;
    flex-direction: row;
    gap: 0.75rem;
    align-items: center;
}

.notificationFeed-body {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 0.25rem;
}

.notificationFeed-body-header {
    position: relative;
    align-items: center;
    display: flex;
    gap: 0.5rem;
}

.notification-type {
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    color: #344054;
    font-style: Inter;
}

.notification-time {
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    color: #475467;
    font-style: Inter;
}

.notificationFeed-container {
    display: flex;
    width: 100%;
    flex-direction: row;
    gap: 0.75rem;
}

.notificationFeed-body-content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 0.75rem;
}

.notificationFeed-body-text-container {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
}

.notificationFeed-body-text {
    display: flex;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: #475467;
    font-style: Inter;
}

.unread-icon {
    position: absolute;
    right: 0;
    height: 0.5rem;
    width: 0.5rem;
}