.modal-container-bg {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    width: 100vw;
    height: 100vh;
    background: rgba(52, 64, 84, 0.7);
    backdrop-filter: blur(8px);
    /* Note: backdrop-filter has minimal browser support */
    position: absolute;
    top: 0rem;
    bottom: 0rem;
    z-index: 5;
}
.modal-container-table-bg {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    background: rgba(52, 64, 84, 0.7);
    backdrop-filter: blur(8px);
    /* Note: backdrop-filter has minimal browser support */
    position: fixed;
    top: 0rem;
    right: 0rem;
    left: 0rem;
    bottom: 0rem;
    z-index: 5;
}
.modal-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    width: 400px;
    height: 328px;
    background: #FFFFFF;
    box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
    border-radius: 12px;
    flex: none;
    order: 0;
    flex-grow: 0;
}
.modal-container-logout {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 0rem 1.5rem 0rem;
    width: 400px;
    background: #FFFFFF;
    box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
    border-radius: 12px;
    flex: none;
    order: 0;
    flex-grow: 0;
}
.modal-header {
    display: flex;
    flex-direction: row;
    /* align-items: center; */
    padding: 0px;
    isolation: isolate;
    
    width: 400px;
    height: 136px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}
.modal-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px 24px;
    gap: 0.5rem;
    
    /* width: 400px; */
    /* height: 92px; */
    
    
    /* Inside auto layout */
    
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}
.modal-actions {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1.5rem 0rem 1.5rem 0rem;
    
    /* Inside auto layout */
    
    flex: none;
    order: 2;
    align-self: stretch;
}
.modal-actions-logout {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 2rem 1.75rem 0rem 1.75rem;
    
    /* Inside auto layout */
    
    flex: none;
    order: 2;
    align-self: stretch;
}
.modal-header-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px 24px 0px;
    gap: 16px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    z-index: 0;
}
.modal-text-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 4px;
    
    width: 352px;
    height: 28px;
    
    
    /* Inside auto layout */
    
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}
.modal-text {
    width: 352px;
    height: 28px;
    
    /* Text lg/Semibold */
    
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    /* identical to box height, or 156% */
    
    
    /* Gray/900 */
    
    color: #101828;
    
    
    /* Inside auto layout */
    
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}
.modal-dropdown {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 0.5rem;
    
    width: 352px;
    height: 92px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}
.modal-actions-content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 12px;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    justify-content: center;
}
.x-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    
    /* position: absolute; */
    width: 2.75rem;
    height: 2.75rem;
    right: 48.25rem;
    flex: none;
    order: 1;
    flex-grow: 0;
    z-index: 1;
    cursor: pointer;
}
.button-container {
    display: flex;
    align-self: flex-start;
    justify-items: flex-end;
    justify-content: flex-end;
    width: 100%;
    padding: 0rem 0.7rem 0rem 0rem;
}
.modal-container-bg-topleft {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    width: 100vw;
    height: 100vh;
    background: rgba(52, 64, 84, 0);
    backdrop-filter: blur(8px);
    /* Note: backdrop-filter has minimal browser support */
    position: absolute;
    top: 0rem;
    left: 0rem;
    bottom: 0rem;
    z-index: 5;
}
.modal-header-crop {
display: flex;
flex-direction: row;
align-items: flex-start;
padding: 24px 24px 20px;
gap: 16px;
/* width: 560px;
height: 76px; */
border-radius: 12px;
background: #FFFFFF;
order: 0;
align-self: stretch;
justify-content: space-between;
flex-grow: 0;
z-index: 0;
}
.modal-header-content-crop {
display: flex;
flex-direction: row;
align-items: flex-start;
/* padding: 24px 24px 0px; */
gap: 16px;
/* width: 560px;
height: 76px; */
background: #FFFFFF;
flex: none;
order: 0;
align-self: stretch;
flex-grow: 0;
z-index: 0;
}
.modal-container-crop {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    width: 560px;
    /* height: 533px; */
    background: #FFFFFF;
    box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
    border-radius: 12px;
    flex: none;
    order: 0;
    flex-grow: 0;
}
.x-button-crop {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* padding-top: 10px; */
    margin-top: -9px;
    margin-right: -10px;
    
    /* position: absolute; */
    width: 2.75rem;
    height: 2.75rem;
    /* right: 28.25rem; */
    flex: none;
    order: 1;
    flex-grow: 0;
    z-index: 1;
    cursor: pointer;
}
.thumb-crop {
    display: flex;
    width: 512px;
    height: 312px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
    border-radius: 8px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    justify-content: center;
}
.modal-content-crop {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px 24px;
    gap: 0.5rem;
    
    /* width: 400px; */
    /* height: 92px; */
    
    
    /* Inside auto layout */
    
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}
.button-container-delete {
    display: flex;
    justify-content: center;
    gap: 12px;
    margin-top: 32px;
    margin-bottom: 24px;
}