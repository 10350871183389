.rmdp-border-left {
    border-left: 0px !important;
}

.rmdp-calendar {
    display: flex !important;
    flex-direction: column !important;
    justify-content: space-between !important;
}

.rmdp-arrow {
    border: solid #667085 !important;
    border-width: 0 2px 2px 0 !important;
}

.rmdp-arrow-container:hover {
    /* border: solid #667085 !important; */
    background-color: #F2F4F7 !important;
    border-width: 0 2px 2px 0 !important;
}

.rmdp-range {
    background-color: #F2F4F7 !important;
    color: #000 !important;
    box-shadow: 0 0 0px !important;
}

.rmdp-range.start {
    color: #ffff !important;
    background: radial-gradient(30px circle at center, #365A6D 79%, #F2F4F7 80%) !important;
    background-color: #F2F4F7 !important;
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
}

.rmdp-range.end {
    color: #ffff !important;
    background: radial-gradient(30px circle at center, #365A6D 79%, #F2F4F7 80%) !important;
    background-color: #F2F4F7 !important;
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
}

.rmdp-day {
    color: #000 !important;
    height: 50px !important;
    width: 50px !important;
}
.rmdp-day.rmdp-today span {
    background-color: transparent !important;
    color: inherit !important;
}

.rmdp-day.rmdp-today.rmdp-range.start span {
    color: #ffff !important;
    background: radial-gradient(30px circle at center, #365A6D 79%, #F2F4F7 80%) !important;
    background-color: #F2F4F7 !important;
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
}

.rmdp-day.rmdp-today.rmdp-range.end span {
    color: #ffff !important;
    background: radial-gradient(30px circle at center, #365A6D 79%, #F2F4F7 80%) !important;
    background-color: #F2F4F7 !important;
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
}

.rmdp-header-values {
    font-family: 'Inter' !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    text-align: center !important;
    color: #344054 !important;
}

.rmdp-week-day {
    font-family: 'Inter' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    color: #344054 !important;
    height: 50px !important;
    width: 50px !important;
}

.rmdp-header {
    margin-top: 0px !important;
    padding: 20px 24px 0px 24px !important;
}

.rmdp-range-hover {
    background-color: #F2F4F7 !important;
    color: #000 !important;
}

.rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover {
    background-color: #365A6D !important;
    color: #FFFFFF !important;
}

.rmdp-deactive {
    color: #667085 !important;
}

.rmdp-disabled {
    color: #667085 !important;
    cursor: not-allowed !important;
}

.preset-column {
    display: flex;
    flex-direction: column;
    padding: 12px 16px;
    gap: 4px;
    border-right: 1px solid #cfd8e2;
}

.preset-button-default {
    display: flex;
    width: 10rem;
    height: 2.5rem;
    padding-left: 1rem;
    border-radius: 0.5rem;
    cursor: pointer;
}

.preset-button-active {
    display: flex;
    width: 10rem;
    height: 2.5rem;
    padding-left: 1rem;
    border-radius: 0.5rem;
    cursor: pointer;
}

.preset-text {
    display: flex;
    align-items: flex-start;
    margin-top: auto;
    margin-bottom: auto;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */
    /* Gray/700 */
    color: #344054;
}

.date-range-custom-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 8px 16px;
}

.date-range-input {
    display: flex;
    gap: 12px;
}

.seperator {
    display: flex;
    margin: auto;
}

.date-range-button {
    display: flex;
    gap: 12px;
}

.date-range-text-input {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 14px;
    width: 136px;
    background: #FFFFFF;
    border: 1px solid #D0D5DD;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
}