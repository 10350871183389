.text-button {
    height: 1.25rem;
    font-family: 'Inter';
    font-style: normal;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #26404E;
    background-color: #ffffff;
    border: none;
    flex: none;
    order: 1;
    flex-grow: 0;
    padding: 0rem;
    cursor: pointer;
}
.text-button:active{
    color: #17272F;
}

.primary-button {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.625rem 1.125rem;
    gap: 0.5rem;
    /* width: 22.5rem; */
    height: 2.75rem;
    background: #365A6D;
    border: 0.063rem solid #365A6D;
    box-shadow: 0rem 0.063rem 0.125rem rgba(16, 24, 40, 0.05);
    border-radius: 0.5rem;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0; 
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #FFFFFF;
    cursor: pointer;
}
.primary-button:active{
    background: #26404E;
}

.secondary-button {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.625rem 1.125rem;
    gap: 0.5rem;
    /* width: 22.5rem; */
    height: 2.75rem;
    background: #FFFFFF;
    border: 1px solid #D0D5DD;
    box-shadow: 0rem 0.063rem 0.125rem rgba(16, 24, 40, 0.05);
    border-radius: 0.5rem;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0; 
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #344054;
    cursor: pointer;

    box-sizing: border-box;
}
.secondary-button:active{
    background: #F9FAFB;
}

.google-button{
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.625rem 1rem;
    gap: 0.75rem;
    width: 22.5rem;
    height: 2.75rem;
    background: #FFFFFF;
    border: 0.063rem solid #D0D5DD;
    box-shadow: 0rem 0.063rem 0.125rem rgba(16, 24, 40, 0.05);
    border-radius: 0.5rem;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #344054;
    flex: none;
    order: 1;
    flex-grow: 0;
    cursor: pointer;
}
.google-button:active{
    background: #F9FAFB;
}

.learn-more-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.625rem 1.125rem;
    gap: 0.5rem;
    background: #FFFFFF;
    border: 0.063rem solid #D0D5DD;
    box-shadow: 0rem 0.063rem 0.125rem rgba(16, 24, 40, 0.05);
    border-radius: 0.5rem;
    cursor: pointer;
}

.manage-asset-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.625rem 1.125rem;
    gap: 0.5rem;
    background: #365A6D;
    color: #FFFFFF;
    border: 0.063rem solid #365A6D;
    box-shadow: 0rem 0.063rem 0.125rem rgba(16, 24, 40, 0.05);
    border-radius: 0.5rem;
    cursor: pointer;
}

.continue-button {
    width: 6.688rem;
    height: 2.75rem;
    /* box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05); */
    border-radius: 0.5rem;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.25rem;
    /* identical to box height, or 150% */

    padding: 0.625rem 1.125rem;
    /* Base/White */

    color: #FFFFFF;
    cursor: pointer;
}

.back-button-container {
    color: #475467;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.25rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    border: 0;
    cursor: pointer;
}

.error-page-redirect-button-container {
    height: 1.25rem;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #26404E;
    background-color: #ffffff;
    border: none;
    flex: none;
    order: 1;
    flex-grow: 0;
    padding: 0rem;
    cursor: pointer;
}
.error-page-redirect-button-column {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
}
.error-page-redirect-button-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.761rem;
}
.error-page-redirect-button-label {
    font-style: normal;
    font-weight: 600;
    line-height: 2.375rem;
    color: #101828;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    font-size: 1rem;
    line-height: 1.5rem;
}
.error-page-redirect-button-description {
    font-style: normal;
    font-weight: 400;
    line-height: 2.375rem;
    color: #475467;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    font-size: 1rem;
    line-height: 1.5rem;
}

.asset-card-refresh-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 0.875rem;
    gap: 0.5rem;
}

.asset-card-get-data-text{
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #26404E
}

.asset-card-refresh-button-container{
    background-color: #FFFFFF;
    border: none;
    cursor: pointer;
}

.asset-card-button-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 0.5rem;
    gap: 0.5rem;
    background: #FFFFFF;
    border: 0.063rem solid #D0D5DD;
    box-shadow: 0rem 0.063rem 0.125rem rgba(16, 24, 40, 0.05);
    border-radius: 0.5rem;
}
.dashboard-insight-button {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.625rem 1rem;
    gap: 0.5rem;
    /* width: 7.125rem; */
    height: 2.5rem;
    background: #365A6D;
    border: 0.063rem solid #D0D5DD;
    box-shadow: 0rem 0.063rem 0.125rem rgba(16, 24, 40, 0.05);
    border-radius: 0.5rem;
    flex: none;
    order: 3;
    align-self: stretch;
    flex-grow: 0; 
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #FFFFFF;
    cursor: pointer;
    box-sizing: border-box;
}
.select-date-button {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.625rem 1rem;
    gap: 0.5rem;
    /* width: 9.063rem; */
    height: 2.5rem;
    background: #FFFFFF;
    border: 1px solid #D0D5DD;
    box-shadow: 0rem 0.063rem 0.125rem rgba(16, 24, 40, 0.05);
    border-radius: 0.5rem;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0; 
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #344054;
    cursor: pointer;
    box-sizing: border-box;
}
.live-map-button {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.625rem 1rem;
    gap: 0.5rem;
    height: 2.5rem;
    background: #FFFFFF;
    border: 1px solid #D0D5DD;
    box-shadow: 0rem 0.063rem 0.125rem rgba(16, 24, 40, 0.05);
    border-radius: 0.5rem;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0; 
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #344054;
    cursor: pointer;
    box-sizing: border-box;
}

.bulk-action-button {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.625rem 1rem;
    gap: 0.5rem;
    height: 2.5rem;
    background: #365A6D;
    border: 0.063rem solid #D0D5DD;
    box-shadow: 0rem 0.063rem 0.125rem rgba(16, 24, 40, 0.05);
    border-radius: 0.5rem;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0; 
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #FFFFFF;
    cursor: pointer;
    box-sizing: border-box;
}

.outlined-button {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 0.875rem;
    gap: 0.5rem;
    /* width: 22.5rem; */
    height: 2.25rem;
    background: #FFFFFF;
    border: 1px solid #D0D5DD;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 0.5rem;
    flex: none;
    order: 0;
    flex-grow: 0;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #344054;
    cursor: pointer;
}
.outlined-button:active{
    background: #D0D5DD;
}

.export-csv-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.625rem 1rem;
    gap: 0.5rem;
    /* Base/White */

    background: #FFFFFF;
    /* Gray/300 */

    border: 0.063rem solid #D0D5DD;
    /* Shadow/xs */

    box-shadow: 0rem 0.063rem 0.125rem rgba(16, 24, 40, 0.05);
    border-radius: 0.5rem;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #344054;
    cursor: pointer;
}

.button-delete {
    padding: 10px 18px;
    gap: 8px;
    background: #D92D20;
    border: 1px solid #D92D20;
    /* Shadow/xs */

    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;

    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
}

.outline-button-delete {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.625rem 1rem;
    background: #FFFFFF;
    border: 1px solid #FDA29B;
    color: #B42318;
    font-size: 0.875rem;
    line-height: 1.25rem;
    gap: 8px;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    font-weight: 600;
    font-family: 'Inter';
    cursor: pointer;
}

.telegram-message-button {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.625rem 1rem;
    gap: 0.5rem;
    height: 2.5rem;
    background: #D0E0E8;
    border: 0.063rem solid #D0E0E8;
    box-shadow: 0rem 0.063rem 0.125rem rgba(16, 24, 40, 0.05);
    border-radius: 0.5rem;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0; 
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #26404E;
    cursor: pointer;
    box-sizing: border-box;
}

.topbar-menu-button {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* padding: 0.625rem 1rem; */
    gap: 0.5rem;
    /* height: 2.5rem; */
    background: initial;
    border: none;
    /* border-radius: 0.5rem; */
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0; 
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #FFFFFF;
    cursor: pointer;
    box-sizing: border-box;
}
