.search-input-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.375rem;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    cursor: default;
}

.search-input {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    position: relative;
}

.iconn-search {
    position: absolute;
    left: 1rem;
}

.inputSearch {
    font-family: 'Inter';
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.625rem 0.875rem;
    gap: 0.5rem;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #101828;
    flex: none;
    order: 0;
    flex-grow: 0;
    border-radius: 0.5rem;
    border: 1px solid #D0D5DD;
    box-shadow: 0rem 0.063rem 0.125rem rgba(16, 24, 40, 0.05);
    align-self: stretch;
}

.inputSearch:placeholder-shown {
    padding-left: 2.5rem;
}