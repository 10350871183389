.agency-users-container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.agency-users-header {
    display: flex;
    flex-direction: column;
    padding: 1.5rem 2rem 0rem;
    gap: 1.5rem;
}

.agency-users-page-header {
    display: flex;
    flex-direction: column;
    padding: 0rem;
    gap: 1.25rem;
}

.agency-users-tabs {
    display: flex;
    padding: 0rem;
    border-bottom: 0.0625rem solid #EAECF0;
}

.agency-users-body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* background: #FFFFFF; */
    margin: 0rem 2rem 3rem;
    border: 0.0625rem solid #EAECF0;
    /* Shadow/sm */
    box-shadow: 0rem 0.0625rem 0.1875rem rgba(16, 24, 40, 0.1), 0rem 0.0625rem 0.125rem rgba(16, 24, 40, 0.06);
    border-radius: 0.75rem;
}

.agency-users-table-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px 24px;
    gap: 1rem;
    width: calc(100% - 3rem);
    border-bottom: 0.0625rem solid #EAECF0;
}

.agency-users-table-header-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0rem;
    gap: 0.25rem;
}

.agency-users-table-title {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
}

.agency-users-header-text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.75rem;
    color: #101828;
}

.agency-users-count {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.125rem 0.5rem;
    background: #D0E0E8;
    mix-blend-mode: multiply;
    border-radius: 1rem;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 1.125rem;
    text-align: center;
    color: #26404E;
}

.agency-users-count-empty {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.125rem 0.5rem;
    background: #FEF3F2;
    mix-blend-mode: multiply;
    border-radius: 1rem;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 1.125rem;
    text-align: center;
    color: #B42318;
}

.agency-users-table-subtitle {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #475467;
}

.agency-users-table-button-group {
    display: flex;
    gap: 0.75rem;
    margin-top: auto;
    margin-bottom: auto;
}


.agency-users-table-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 37.875rem;
    overflow-y: auto;
    /* border-bottom-left-radius: 0.75rem; */
    /* border-bottom-right-radius: 0.75rem; */
}

.agency-users-empty {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 22rem;
    min-height: 17.875rem;
    margin: auto;
    gap: 1.25rem;
}