.add-btn {
    display: flex;
    gap: 8px;
    background-color: #FFFF;
    border: none;
    padding: 6px 0px 0px;
}

.invite-users-text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    /* Primary/700 */
    color: #26404E;
}