.dashboard-layout-container {
    /* display: flex; 
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%; */
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-columns: 280px 1fr;
    grid-template-rows: 1fr;
    gap: 24px 0px;
    grid-template-areas:
        ". .";
    overflow: hidden;
    overflow-x: hidden;
    overflow-y: hidden;
}

.sidebar {
    width: 17.5rem;
    top: 0;
    left: 0;
    overflow-y: auto;
}

.main-content {
    width: calc(100vw - 17.5rem);
    left: 17.5rem;
    overflow: auto;
    height: 100vh;
}

.dashboard-layout-container-mobile {
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-columns: 1fr;
    grid-template-rows: 64px 1fr;
    grid-template-areas: 
    "."
    ".";
    overflow: hidden;
    overflow-x: hidden;
    overflow-y: hidden;
}

.topbar {
    width: auto;
    height: 64px;
    top: 0;
    overflow-y: auto;
}

.main-content-mobile {
    overflow: auto;
    height: 100%;
}