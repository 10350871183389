.input-disable {
    display: flex;
    height: 100%;
    background: #F9FAFB;
    min-height: 96px;
}

.input-enabled {
    display: flex;
    height: 100%;
    background: #FFFFFF;
    min-height: 96px;
}

.rti--container {
    --rti-bg: inherit !important;
    width: 374px !important;
    height: auto !important;
    align-items: baseline !important;
    padding: 12px !important;
    border: 1px solid #D0D5DD !important;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05) !important;
    border-radius: 8px !important;
}

.disabled {
background: #F9FAFB;
}

.rti--tag {
    background: #FFFFFF !important;
    border: 1px solid #D0D5DD !important;
    border-radius: 6px !important;
    font-family: 'Inter' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    color: #344054 !important;
}