.system-status-main {
    width: 15.5rem;
    cursor: default;
}
.card-monthly-budget {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1.25rem 1rem;
    gap: 0.5rem;
    background: #F9FAFB;
    border-radius: 0.5rem;
    order: 1;
    align-self: stretch;
}
.text-container-system-status {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0rem;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}
.text-system-status {
    width: 12rem;
    height: 1.25rem;
    font-style: normal;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #101828;
    flex: none;
    order: 0;
    flex-grow: 1;
}
.action-button-dimiss-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0rem;
    gap: 0.5rem;
    width: 3.375rem;
    height: 1.25rem;
    flex: none;
    order: 0;
    flex-grow: 0;
}
.action-button-upgrade-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0rem;
    gap: 0.5rem;
    width: 5.688rem;
    height: 1.25rem;
    flex: none;
    order: 1;
    flex-grow: 0;
}
.action-button-dismiss {
    width: 3.375rem;
    height: 1.25rem;
    font-style: normal;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #475467;
    flex: none;
    order: 0;
    flex-grow: 0;
}
.action-button-upgrade-plan {
    width: 5.688rem;
    height: 1.25rem;
    font-style: normal;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #26404E;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.text-system-beta-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
}

.text-system-beta-title {
    display: flex;
    padding-right: 0px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
}

.text-system-beta-text-container {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.text-system-beta-text {
    display: flex;
    flex-direction: row;
    gap: 4px;
    flex: 1 0 0;
    color: #344054;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}

.text-system-beta-content {
    display: flex;
    flex-direction: column;
    align-self: stretch;
    color: #475467;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}

.system-status-beta-text {
    text-decoration: none;
    color: #475467;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    background-color: #F9FAFB 
}