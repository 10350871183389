.config-tab-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0rem;
    isolation: isolate;
    filter: drop-shadow(0rem 0.063rem 0.125rem rgba(16, 24, 40, 0.05));
    border-radius: 0.5rem;
}

.config-tab-text {
    margin: auto;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #26404E;
}

.config-tab-button-group {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    gap: 1.5rem;
}

.config-tab-users {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.125rem 0.5rem;
    background: #D0E0E8;
    mix-blend-mode: multiply;
    border-radius: 1rem;
    height: 1.375rem;
    width: auto;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 1.25rem;
    color: #26404E;
}

.config-tab-users-empty {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.125rem 0.5rem;
    background: #FEF3F2;
    mix-blend-mode: multiply;
    border-radius: 1rem;
    height: 1.375rem;
    width: auto;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 1.125rem;
    text-align: center;
    color: #B42318;
}
/* 
.config-tab-text-badge {
    margin: auto;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 1.25rem;
    color: #26404E;
} */