.user-modal-header {
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    width: auto;
    padding: 1.5rem 1.5rem 0rem;
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem;
    gap: 1rem;
}

.icon-button-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.icon-container {
    display: flex;
    box-sizing: border-box;
    width: 48px;
    height: 48px;
    /* Base/White */
    background: #FFFFFF;
    /* Gray/200 */
    border: 1px solid #EAECF0;
    /* Shadow/xs */
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 10px;
}

.x-button-container {
    display: flex;
}

.user-x-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 2.75rem;
    height: 2.75rem;
    cursor: pointer;
}

.user-modal-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px 24px 0px;
    gap: 6px;
    width: 100%;
    box-sizing: border-box;
}

.user-modal-text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */
    /* Gray/700 */
    color: #344054;
}

.access-privilege-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px 24px 0px;
    gap: 6px;
    width: 100%;
    box-sizing: border-box;
}

.users-button-container {
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: flex-start;
    padding: 20px 24px;
    width: auto;
}