.img-with-class-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 22.5rem;
    cursor: default;
}

.img-with-class-header-text {
    margin-top: 1.25rem;
    font-weight: 600;
    font-size: 1.875rem;
    line-height: 2.375rem;
    text-align: center;
}

.img-with-class-subtext {
    margin-top: 0.75rem;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    text-align: center;
    color: #475467;
}